import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useState } from 'react'

import { FetchError } from '../utils/errorTools'

export type UseApiLazy<TData = unknown, TBody = unknown> = [
  DoApiRequestFn<TData, TBody>,
  UseApiLazyState<TData>,
  { reset: () => void }
]

/**
 * Async execute function that catches errors and returns result monad
 */
export type DoApiRequestFn<TData = unknown, TBody = unknown> = (
  url: string,
  options?: { loginRequired?: boolean } & ApiFetchOptions<TBody>
) => Promise<ApiFetchResult<TData>>

export interface ApiFetchLeft {
  _tag: 'Left'
  error: Error
}

export interface ApiFetchRight<T> {
  _tag: 'Right'
  data: T
}

export type ApiFetchResult<T> = ApiFetchLeft | ApiFetchRight<T>

export type ApiFetchOptions<TBody = unknown> = { body?: TBody } & Omit<RequestInit, 'body'>
export interface UseApiLazyState<TData = unknown> {
  error: Error | null
  called: boolean
  loading: boolean
  data: TData | null
}

const getInitialState = <TData = unknown>(): UseApiLazyState<TData> => {
  return {
    error: null,
    called: false,
    loading: false,
    data: null,
  }
}

const left = (error: Error): ApiFetchLeft => ({
  _tag: 'Left',
  error,
})

const right = <T>(data: T): ApiFetchRight<T> => ({
  _tag: 'Right',
  data,
})

const parseFetchOptions = <TBody>(
  fetchOptions: ApiFetchOptions<TBody> | undefined,
  { accessToken }: { accessToken?: string }
): RequestInit => {
  const { body, headers, ...restOptions } = fetchOptions || {}
  let parsedBody
  if (body) {
    if (body instanceof File) {
      parsedBody = body
    } else if (Array.isArray(body) || typeof body === 'object') {
      parsedBody = JSON.stringify(body)
    }
  }
  const parsedHeaders: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': body instanceof File ? body.type : 'application/json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...headers,
  }
  return {
    ...restOptions,
    headers: {
      ...parsedHeaders,
    },
    body: parsedBody,
  }
}

const executeFetch = async <TData, TBody>(
  url: string,
  { accessToken, fetchOptions }: { accessToken?: string; fetchOptions?: ApiFetchOptions<TBody> }
): Promise<TData> => {
  const options = parseFetchOptions(fetchOptions, { accessToken })
  const response = await fetch(url, options)
  const responseData = (await response.json()) as unknown
  if (response.ok) {
    return responseData as TData
  }
  throw new FetchError(
    `Fetch operation unsuccessful (status: ${response.status} ${response.statusText})`,
    response,
    responseData
  )
}

export const useApiLazy = <TData = unknown, TBody = unknown>(): UseApiLazy<TData, TBody> => {
  const { getAccessTokenSilently } = useAuth0()
  const [state, setState] = useState<UseApiLazyState<TData>>(getInitialState())

  const getAccessToken = useCallback(
    async ({ loginRequired }): Promise<string | undefined> => {
      try {
        return await getAccessTokenSilently()
      } catch (accessTokenError) {
        // ignore error if token not required and do request without token
        if (loginRequired) {
          throw accessTokenError
        }
        return undefined
      }
    },
    [getAccessTokenSilently]
  )

  const doApiRequest = useCallback(
    async (
      url: string,
      options: { loginRequired?: boolean } & ApiFetchOptions<TBody> = {}
    ): Promise<ApiFetchResult<TData>> => {
      const { loginRequired, ...fetchOptions } = options
      setState((prevState) => ({
        ...prevState,
        error: null,
        called: true,
        loading: true,
        data: null,
      }))
      let accessToken: string | undefined = undefined
      try {
        accessToken = await getAccessToken({ loginRequired })
      } catch (accessTokenError) {
        const error =
          accessTokenError instanceof Error ? accessTokenError : new Error(`${accessTokenError}`)
        setState((prevState) => ({
          ...prevState,
          error,
          data: null,
          called: true,
          loading: false,
        }))
        return left(error)
      }
      if (loginRequired && !accessToken) {
        const error = new Error(
          'Unexpected empty access token for API lazy query with login required'
        )
        setState((prevState) => ({
          ...prevState,
          error,
          data: null,
          called: true,
          loading: false,
        }))
        return left(error)
      }
      try {
        const data = await executeFetch<TData, TBody>(url, { accessToken, fetchOptions })
        setState((prevState) => ({
          ...prevState,
          data,
          error: null,
          called: true,
          loading: false,
        }))
        return right(data)
      } catch (fetchError) {
        const error = fetchError instanceof Error ? fetchError : new Error(`${fetchError}`)
        setState((prevState) => ({
          ...prevState,
          data: null,
          error,
          called: true,
          loading: false,
        }))
        return left(error)
      }
    },
    [getAccessToken]
  )

  const reset = useCallback(() => {
    setState({ ...getInitialState() })
  }, [])

  const { data, called, loading, error } = state

  return [doApiRequest, { data, called, loading, error }, { reset }]
}
