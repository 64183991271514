import {
  ApolloError,
  LazyQueryHookOptions,
  LazyQueryResult,
  QueryLazyOptions,
  TypedDocumentNode,
  useLazyQuery,
} from '@apollo/client'
import { DocumentNode } from 'graphql'
import { useCallback, useState } from 'react'
import { useAuth } from '../lib/auth'
import { useCustomerId } from './useCustomerId'

export type CamelLazyQueryTuple<TData, TVariables> = [
  (
    options?: QueryLazyOptions<TVariables>
  ) => Promise<LazyQueryResult<TData, TVariables> | { data: undefined; error: Error }>,
  Omit<LazyQueryResult<TData, TVariables>, 'error'> & {
    error?: ApolloError | Error
  }
]

export type UseCamelLazyQueryOptions<
  TData = unknown,
  TVariables = Record<string, unknown>
> = LazyQueryHookOptions<TData, OmitProvided<TVariables>> & AdditionalOptions

export interface AdditionalOptions {
  noAuth?: boolean
}

type OmitProvided<TVariables> = Omit<TVariables, 'customerId'>

export type UseCamelLazyQuery<TData, TVariables> = CamelLazyQueryTuple<
  TData,
  OmitProvided<TVariables>
>

const defaultOptions: AdditionalOptions = { noAuth: false }

const useCamelLazyQuery = <TData = unknown, TVariables = Record<string, unknown>>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: UseCamelLazyQueryOptions<TData, TVariables>
): UseCamelLazyQuery<TData, TVariables> => {
  const { noAuth, ...hookOptions } = { ...defaultOptions, ...(options || {}) }
  const { getAccessTokenSilently } = useAuth()
  const customerId = useCustomerId()
  const [tokenError, setTokenError] = useState<Error | null>(null)
  const [runLazyQuery, { error: queryError, ...queryResultProps }] = useLazyQuery(
    query,
    hookOptions
  )

  const runCamelLazyQuery = useCallback(
    async (queryOptions?: QueryLazyOptions<OmitProvided<TVariables>>) => {
      setTokenError(null)
      try {
        let accessToken: string | undefined = undefined
        if (!noAuth) {
          accessToken = await getAccessTokenSilently()
        }
        return runLazyQuery({
          ...queryOptions,
          // Although the added `customerId` variable will be returned, there's no need to indicate
          // it's addition to the return type. We cast the variables type to the generic parameter
          // instead to maintain expected return type.
          variables: ({
            ...(queryOptions && queryOptions.variables),
            customerId,
          } as unknown) as TVariables,
          context: {
            ...(queryOptions && queryOptions.context),
            uri: process.env.GATSBY_CAMEL_URL,
            token: accessToken,
          },
        })
      } catch (accessTokenError) {
        const error =
          accessTokenError instanceof Error ? accessTokenError : new Error(`${accessTokenError}`)
        setTokenError(error)
        return {
          data: undefined,
          error,
        }
      }
    },
    [noAuth, customerId, runLazyQuery, getAccessTokenSilently]
  )

  return [runCamelLazyQuery, { ...queryResultProps, error: tokenError || queryError }]
}

export default useCamelLazyQuery
