import {
  EScriptUpdateCartAction,
  EScriptUpdateCartActionAdd,
  EScriptUpdateCartActionEmpty,
} from './types'

export const isEmptyCartAction = (
  action: EScriptUpdateCartAction
): action is EScriptUpdateCartActionEmpty => action.operation === 'empty'

export const isAddToCartAction = (
  action: EScriptUpdateCartAction
): action is EScriptUpdateCartActionAdd => action.operation === 'add'
