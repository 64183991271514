import { useCallback, useMemo } from 'react'

import useSiteMetadata from '../../../hooks/useSiteMetadata'

export interface UseTestKitProducts {
  testKitSkus: string[]
  isTestKitSku: (sku: string) => boolean
}

export const useTestKitProducts = (): UseTestKitProducts => {
  const siteMetadata = useSiteMetadata()

  const testKitSkus = useMemo(() => siteMetadata?.testKitProducts?.map(({ sku }) => sku) ?? [], [
    siteMetadata?.testKitProducts,
  ])

  const isTestKitSku = useCallback(
    (sku: string) => testKitSkus.some((testKitSku) => testKitSku === sku),
    [testKitSkus]
  )

  return { testKitSkus, isTestKitSku }
}
