import { useMemo } from 'react'
import { usePromisify } from '../../../hooks/usePromisify'
import { useAuth, UseAuth } from './useAuth'

export type UseAuthAsync = Omit<UseAuth, 'isLoading' | 'error' | 'isAuthenticated' | 'user'> & {
  loadAuthentication: () => Promise<Authentication>
}

export type Authentication = Pick<UseAuth, 'isAuthenticated' | 'user'>

export const useAuthAsync = (): UseAuthAsync => {
  const { isLoading, error, isAuthenticated, user, ...authContextRest } = useAuth()

  const data = useMemo<Authentication>(() => ({ isAuthenticated, user }), [isAuthenticated, user])

  const { load } = usePromisify({
    loading: isLoading,
    error,
    data,
  })

  return { loadAuthentication: load, ...authContextRest }
}
