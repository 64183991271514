import { useCallback } from 'react'

import { useApiLazy } from '../../../hooks/useApiLazy'
import { setDispensaryUserState } from '../events/user'
import { UserDispensariesPutBody, UserMetadataDispensaries } from '../types'
import { useDispensaryContext } from './useDispensaryContext'
import { UseShouldUpdateDispensary } from './useShouldUpdateDispensary'

export type UseDispensaryUpdateUser = [
  updateUserDispensary: (options: { shouldUpdateDispensary: boolean }) => Promise<void>
]

export const useDispensaryUpdateUser = ({
  refreshClaims,
}: {
  refreshClaims: UseShouldUpdateDispensary['refreshClaims']
}): UseDispensaryUpdateUser => {
  const [doApiRequest] = useApiLazy<UserMetadataDispensaries, UserDispensariesPutBody>()
  const dispensary = useDispensaryContext()

  const updateUserDispensary = useCallback(
    async ({ shouldUpdateDispensary }: { shouldUpdateDispensary: boolean }): Promise<void> => {
      if (!dispensary) {
        setDispensaryUserState({ type: 'user_final' })
        return
      }
      if (!shouldUpdateDispensary) {
        setDispensaryUserState({ type: 'user_final' })
        return
      }
      const { isInvalid, practitionerCode } = dispensary
      if (isInvalid) {
        setDispensaryUserState({ type: 'user_final' })
        return
      }
      setDispensaryUserState({ type: 'user_update_start', practitionerCode })
      const result = await doApiRequest('/api/user/dispensaries', {
        loginRequired: true,
        method: 'PUT',
        body: {
          data: {
            practitionerCodeRecent: practitionerCode,
          },
        },
      })
      if (result._tag === 'Left') {
        setDispensaryUserState({ type: 'user_error', error: result.error })
        // TODO: can we remove the separate final state?
        setDispensaryUserState({ type: 'user_final' })
        throw result.error
      }
      try {
        await refreshClaims()
        setDispensaryUserState({ type: 'user_update_complete', practitionerCode })
      } catch (errorObj) {
        const error = errorObj instanceof Error ? errorObj : new Error(`${errorObj}`)
        setDispensaryUserState({ type: 'user_error', error })
        throw error
      } finally {
        // TODO: can we remove the separate final state?
        setDispensaryUserState({ type: 'user_final' })
      }
    },
    [doApiRequest, dispensary, refreshClaims]
  )

  return [updateUserDispensary]
}
