import { createContext } from 'react'
import { SnackbarKey } from 'notistack'

export type ToastId = string | SnackbarKey

export interface ToastContextValues {
  toasts: ToastId[]
  dismissed: ToastId[]
  isToastShown: (toastId: ToastId) => boolean
  showToast: (toastId: ToastId) => void
  hideToast: (toastId: ToastId) => void
  isToastDismissed: (toastId: ToastId) => boolean
  dismissToast: (toastId: ToastId) => void
}

export const ToastContext = createContext<ToastContextValues>({
  toasts: [],
  dismissed: [],
  isToastShown: () => false,
  showToast: () => {
    // noop
  },
  hideToast: () => {
    // noop
  },
  isToastDismissed: () => false,
  dismissToast: () => {
    // noop
  },
})
