import type { ApolloError } from '@apollo/client'
import { useCallback } from 'react'
import {
  Dispensary,
  GetDispensaryByCodeDocument,
  GetDispensaryByCodeQueryVariables,
} from '../../../graphql/camel'
import useCamelLazyQuery from '../../../hooks/useCamelLazyQuery'

export type UseDispensaryByCodeLazy = [
  getDispensaryByCode: (options: GetDispensaryByCodeQueryVariables) => void,
  result: {
    loading: boolean
    data: Dispensary | null
    error?: ApolloError | Error | string
    called: boolean
  }
]

export const useDispensaryByCodeLazy = (): UseDispensaryByCodeLazy => {
  const [
    doGetDispensaryByCode,
    { loading, data: responseData, error, called },
  ] = useCamelLazyQuery(GetDispensaryByCodeDocument, { noAuth: true })

  const data = responseData?.getDispensaryByCode || null

  const getDispensaryByCode = useCallback(
    ({ practitionerCode }: GetDispensaryByCodeQueryVariables) => {
      doGetDispensaryByCode({ variables: { practitionerCode } })
    },
    [doGetDispensaryByCode]
  )

  return [
    getDispensaryByCode,
    {
      loading,
      data,
      error,
      called,
    },
  ]
}
