import { createContext } from 'react'
import { WindowLocation } from '@gatsbyjs/reach-router'

export interface LocationContextValue {
  location: WindowLocation | null
  setLocation: (location: WindowLocation) => void
}

export const LocationContext = createContext<LocationContextValue>({
  location: null,
  setLocation: () => {
    // noop
  },
})
