import { useCallback, useEffect, useRef } from 'react'
import { ApolloError } from '@apollo/client'
import { Deferred } from '../utils/deferred'

export interface UsePromisifyLazy<TArgs extends unknown[], TData> {
  load: (...args: TArgs) => Promise<TData>
}

export interface UsePromisifyLazyOptions<TArgs extends unknown[], TData> {
  doRequest: (...args: TArgs) => void
  data: TData
  called: boolean
  loading: boolean
  error: ApolloError | Error | undefined
}

export const usePromisifyLazy = <TArgs extends unknown[], TData>({
  doRequest,
  called,
  loading,
  error,
  data,
}: UsePromisifyLazyOptions<TArgs, TData>): UsePromisifyLazy<TArgs, TData> => {
  const deferredRef = useRef(new Deferred<TData>())

  useEffect(() => {
    const deferred = deferredRef.current
    if (deferred.isCompleted) {
      return
    }
    if (!called) {
      return
    }
    if (loading) {
      return
    }
    if (error) {
      deferred.reject(error)
      return
    }
    deferred.resolve(data)
  }, [loading, error, data, called])

  const load = useCallback(async (...args: TArgs): Promise<TData> => {
    doRequest(...args)
    return deferredRef.current.promise
    // intentionally exclude `doRequest` dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { load }
}
