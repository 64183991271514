import { useCallback, useMemo } from 'react'
import type { User } from '@auth0/auth0-react'
import { useAuth } from '../lib/auth'
import { userClaimIds, UserCategory, isUserCategory } from '../utils/userClaims'

export interface UseUserCategory {
  userCategory: UserCategory | undefined
  parseUserCategory: (user: User | undefined) => UserCategory | undefined
}

const useUserCategory = (): UseUserCategory => {
  const { user } = useAuth()

  const parseUserCategory = useCallback((userToParse: User | undefined):
    | UserCategory
    | undefined => {
    const userCategory = userToParse ? (userToParse[userClaimIds.category] as unknown) : undefined
    return isUserCategory(userCategory) ? userCategory : undefined
  }, [])

  const userCategory = useMemo(() => parseUserCategory(user), [parseUserCategory, user])
  return {
    userCategory,
    parseUserCategory,
  }
}

export default useUserCategory
