import { createKeyedSignal } from '@react-rxjs/utils'
import { Dispatch, useCallback, useEffect, useState } from 'react'
import { filter, fromEvent, map, of, startWith, withLatestFrom } from 'rxjs'

import { localOrFallbackStorage } from '../utils/storage'

export type UseLocalStorage = [value: string, setItem: Dispatch<string>, clearItem: () => void]

const isSSR = typeof window === 'undefined'
const localStorageEvent$ = isSSR ? of() : fromEvent<StorageEvent>(window, 'storage')
const [localStorageState$, setLocalStorageState] = createKeyedSignal<string, string | null>()

function subscribeToLocalStorageChanges({
  key,
  updateComponentState,
  getComponentState,
}: {
  key: string
  updateComponentState: (newValue: string | null) => void
  getComponentState: () => string
}) {
  const subscriptions = [
    // Update in-memory state when localStorage changes
    localStorageEvent$
      .pipe(
        filter((event): event is StorageEvent & { key: string } => event?.key === key),
        filter((event) => event.newValue !== event.oldValue),
        withLatestFrom(
          localStorageState$(key).pipe(startWith(localOrFallbackStorage.getItem(key)))
        ),
        filter(([event, lastLocalStorageState]) => event.newValue !== lastLocalStorageState),
        map(([event]) => event)
      )
      .subscribe((event) => setLocalStorageState(event.key, event.newValue || '')),

    // Update localStorage when in-memory state changes
    localStorageState$(key)
      .pipe(filter((newValue) => localOrFallbackStorage.getItem(key) !== newValue))
      .subscribe((newValue) =>
        newValue === null
          ? localOrFallbackStorage.removeItem(key)
          : localOrFallbackStorage.setItem(key, newValue)
      ),

    // Update component state when in-memory state changes
    localStorageState$(key)
      .pipe(filter((newValue) => getComponentState() !== newValue))
      .subscribe(updateComponentState),
  ]

  return () => subscriptions.forEach((subscription) => subscription.unsubscribe())
}

export const useLocalStorage = (key: string, defaultValue = ''): UseLocalStorage => {
  const storedValue = localOrFallbackStorage.getItem(key) || ''
  const [value, setValue] = useState<string>(storedValue || defaultValue)

  const clearItem = useCallback(() => setLocalStorageState(key, null), [key])
  const setItem = useCallback((newValue: string) => setLocalStorageState(key, newValue), [key])

  useEffect(() => {
    const unsubscribe = subscribeToLocalStorageChanges({
      key,
      updateComponentState: (newValue) => setValue(newValue || ''),
      getComponentState: () => value,
    })
    return unsubscribe
  }, [key, value])

  return [value, setItem, clearItem]
}
