import { createContext } from 'react'
import { PageTemplateQuery } from '../../graphql/gatsby'
import { SanityContext, sanityDefaultContext } from './context-based-content'
import { SanityUserRole } from './roles'

export interface SanityUserRolesContextValue {
  isLoading: boolean
  userRoles: readonly SanityUserRole[]
}

export const SanityUserRolesContext = createContext<SanityUserRolesContextValue>({
  isLoading: false,
  userRoles: [],
})

export type SanitySiteContextValue = SanityContext

export const SanitySiteContext = createContext<SanitySiteContextValue>(sanityDefaultContext)

export type SanityPageContextValue = Partial<Omit<PageTemplateQuery, 'page'>>

export const SanityPageContext = createContext<SanityPageContextValue>({})
