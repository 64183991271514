import { state } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'
import { EMPTY, map, Observable, of, race, scan, switchMap, take, timer } from 'rxjs'

import { CustomError, logAndCaptureException } from '../../utils/errorTools'

/**
 * Observables for coordinating the logging in process.
 *
 * Sequence:
 * - `/logging-in` page creates and holds subscription to state observables
 * - Auth0 redirect callback (executed after PKCE flow):
 *   - Checks whether arbitration is enabled
 *   - If not enabled, callback proceeds immediately
 *   - If enabled, callback registers as handler and waits for arbitration
 * - Accounts site callback (`/logging-in` page) assumes arbitration is enabled, registers, and
 *     waits for arbitration
 * - Fallback handler (`/logging-in` page) assumes arbitration is enabled, registers, and waits
 *     for arbitration
 * - Accounts site callback submits `accountsReturnTo` query param and `isAuthenticated` as evidence
 * - Arbitrator makes selection as soon as sufficient evidence presented
 */

const [redirectArbitrationSignal$, toggleRedirectArbitration] = createSignal<boolean>()

export { toggleRedirectArbitration }

export const redirectArbitrationStatus$ = state(redirectArbitrationSignal$, false)

type RedirectHandler = 'auth0_redirect' | 'accounts_redirect' | 'fallback'

const [redirectHandlerSignal$, registerRedirectHandler] = createSignal<RedirectHandler>()

export { registerRedirectHandler }

interface RedirectEvidence {
  // `null` indicates param is not present (to distinguish from evidence not presented)
  accountsReturnTo?: string | null
  isAuthenticated?: boolean
}

const [redirectEvidenceSignal$, submitRedirectEvidence] = createSignal<RedirectEvidence>()

export { submitRedirectEvidence }

// collect handlers into a set
export const redirectHandlers$ = state<ReadonlySet<RedirectHandler>>(
  redirectHandlerSignal$.pipe(
    scan((handlers, handler) => {
      handlers.add(handler)
      return handlers
    }, new Set())
  ),
  new Set()
)

export const redirectEvidence$ = state<Readonly<RedirectEvidence>>(
  redirectEvidenceSignal$.pipe(
    scan((evidence, newEvidence) => ({ ...evidence, ...newEvidence }), {})
  ),
  {}
)

class RedirectHandlerNotSelectedError extends CustomError {
  name = 'RedirectHandlerNotSelectedError'
  handlers: ReadonlyArray<RedirectHandler>
  evidence: Readonly<RedirectEvidence>

  constructor(handlers: ReadonlyArray<RedirectHandler>, evidence: Readonly<RedirectEvidence>) {
    super('Redirect handler not selected')
    this.handlers = handlers
    this.evidence = evidence
  }
}

export type RedirectHandlerSelection =
  | { handler: 'auth0_redirect' }
  | { handler: 'accounts_redirect'; accountsReturnTo: string }
  | { handler: 'fallback' }

export const mkAuth0RedirectSelection = (): RedirectHandlerSelection => ({
  handler: 'auth0_redirect',
})

const mkAccountsRedirectSelection = (accountsReturnTo: string): RedirectHandlerSelection => ({
  handler: 'accounts_redirect',
  accountsReturnTo,
})

const mkFallbackSelection = (): RedirectHandlerSelection => ({ handler: 'fallback' })

/**
 * Arbitrate redirect handler selection (assumes arbitration is enabled).
 *
 * Observable completes after arbitrating once.
 */
export const arbitrateRedirect$: Observable<
  RedirectHandlerSelection | undefined
> = redirectHandlers$.pipe(
  switchMap((handlers) =>
    race(
      redirectEvidence$.pipe(
        switchMap((evidence) => {
          // e.g. user returned from accounts site after registering
          if (
            evidence.accountsReturnTo &&
            evidence.isAuthenticated &&
            handlers.has('accounts_redirect')
          ) {
            return of(mkAccountsRedirectSelection(evidence.accountsReturnTo))
          }
          // e.g. user returned from accounts site without registering or logging in
          if (
            evidence.accountsReturnTo &&
            evidence.isAuthenticated === false &&
            handlers.has('fallback')
          ) {
            return of(mkFallbackSelection())
          }
          // e.g. user returned from Auth0 login (authorization code flow)
          if (evidence.accountsReturnTo === null && handlers.has('auth0_redirect')) {
            return of(mkAuth0RedirectSelection())
          }
          return EMPTY
        })
      ),
      // timeout after 5s
      timer(5000).pipe(
        switchMap(() => {
          // if Auth0 redirect handler is available, use as fallback
          if (handlers.has('auth0_redirect')) {
            return of(mkAuth0RedirectSelection())
          }
          if (handlers.has('fallback')) {
            return of(mkFallbackSelection())
          }
          return redirectEvidence$.pipe(
            map((evidence) => {
              logAndCaptureException(
                new RedirectHandlerNotSelectedError([...handlers.values()], evidence)
              )
              return undefined
            })
          )
        })
      )
    )
  ),
  // complete after arbitrating once
  take(1)
)
