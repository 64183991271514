import { isBefore, isValid, parseISO, subDays } from 'date-fns'
import { completedCartsConfig } from './config'
import { CompletedCartsListEntry } from './types'
import { isCompletedCartWithUserId, reduceCompletedCartsListEntry, tryJsonParse } from './utils'
import { localOrFallbackStorage } from '../../utils/storage'

interface GarbageCollectReduceResult {
  keysToRemove: string[]
  keysToRetain: string[]
  newCompletedCarts: Array<CompletedCartsListEntry>
}

function tryRemoveStorageKey(storageKey: string): void {
  try {
    localOrFallbackStorage.removeItem(storageKey)
  } catch (err: unknown) {
    console.error(`Error removing storage item (key: ${storageKey}):`, err)
  }
}

function garbageCollectionReducer(
  acc: GarbageCollectReduceResult,
  listEntry: CompletedCartsListEntry
): GarbageCollectReduceResult {
  const { storageKey } = listEntry
  try {
    const storageItem = localOrFallbackStorage.getItem(storageKey)
    const parsedItem = storageItem ? JSON.parse(storageItem) : undefined
    if (!isCompletedCartWithUserId(parsedItem)) {
      console.error(`Invalid item in storage (key: ${storageKey}`)
      return {
        ...acc,
        keysToRemove: [...acc.keysToRemove, storageKey],
      }
    }
    const completedAtDate = parseISO(parsedItem.completedAt)
    const oldestDateAllowed = subDays(new Date(), completedCartsConfig.ttlDays)
    if (!isValid(completedAtDate) || isBefore(completedAtDate, oldestDateAllowed)) {
      return {
        ...acc,
        keysToRemove: [...acc.keysToRemove, storageKey],
      }
    }

    return {
      ...acc,
      newCompletedCarts: [...acc.newCompletedCarts, listEntry],
    }
  } catch (err: unknown) {
    console.error(`Error checking item in storage (${JSON.stringify(listEntry)}):`, err)
    return {
      ...acc,
      keysToRemove: [...acc.keysToRemove, storageKey],
    }
  }
}

export function garbageCollect(): void {
  try {
    const completedCartsRawValue = localOrFallbackStorage.getItem(
      completedCartsConfig.collectionKey
    )
    const completedCartsParsed = completedCartsRawValue
      ? tryJsonParse(completedCartsRawValue)
      : undefined

    if (!Array.isArray(completedCartsParsed)) {
      // nothing to do
      return
    }

    const completedCarts = completedCartsParsed.reduce(reduceCompletedCartsListEntry, [])
    const { keysToRemove, newCompletedCarts } = completedCarts.reduce(garbageCollectionReducer, {
      keysToRemove: [],
      keysToRetain: [],
      newCompletedCarts: [],
    })

    keysToRemove.forEach(tryRemoveStorageKey)

    // update if entries were removed
    if (completedCartsParsed.length !== newCompletedCarts.length) {
      localOrFallbackStorage.setItem(
        completedCartsConfig.collectionKey,
        JSON.stringify(newCompletedCarts)
      )
    }
  } catch (err: unknown) {
    console.error(`Storage garbage collection error:`, err)
  }
}
