import React, { FC, useContext, useEffect, useState } from 'react'
import { WindowLocation } from '@gatsbyjs/reach-router'
import { LocationContext } from '../lib/pages/context'

export const LocationContextProvider: FC = ({ children }) => {
  const [location, setLocation] = useState<WindowLocation | null>(null)
  return (
    <LocationContext.Provider value={{ location, setLocation }}>
      {children}
    </LocationContext.Provider>
  )
}

export const LocationContextUpdate: FC<{ location: WindowLocation }> = ({ children, location }) => {
  const { setLocation } = useContext(LocationContext)

  useEffect(() => {
    setLocation(location)
  }, [location, setLocation])

  return <>{children}</>
}
