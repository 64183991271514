const USER_NAMESPACE = process.env.GATSBY_AUTH0_USER_NAMESPACE as string

export type UserClaimId = UserClaimIdMapping[keyof UserClaimIdMapping]
export type UserCategory = UserCategoryMapping[keyof UserCategoryMapping]

export interface StateMachineClaim {
  state: string
  context?: Record<string, unknown>
}

type UserClaimIdMapping = typeof userClaimIds
type UserCategoryMapping = typeof userCategories

export const userClaimIds = {
  category: `${USER_NAMESPACE}category`,
  companyName: `${USER_NAMESPACE}company_name`,
  netSuiteId: `${USER_NAMESPACE}netsuite_id`,
  previousNetSuiteIds: `${USER_NAMESPACE}previous_netsuite_ids`,
  hipaaCoveredEntity: `${USER_NAMESPACE}hipaa_covered_entity`,
  privacyQualifiers: `${USER_NAMESPACE}privacy_qualifiers`,
  acceptedAgreements: `${USER_NAMESPACE}accepted_agreements`,
  onboardPatient: `${USER_NAMESPACE}onboard_patient`,
  onboardPractitioner: `${USER_NAMESPACE}onboard_practitioner`,
  dispensaryExternalEffect: `${USER_NAMESPACE}dispensary_external_effect`,
  dispensaryPractitionerCode: `${USER_NAMESPACE}dispensary_practitioner_code`,
  dispensaryPractitionerCodeRecent: `${USER_NAMESPACE}dispensary_practitioner_code_recent`,
  magentoUserGroup: `${USER_NAMESPACE}magento_user_group`,
} as const

export const userCategories = {
  employee: 'employee',
  patient: 'patient',
  practitioner: 'practitioner',
  otherProfessional: 'other_professional',
} as const

export function isUserCategory(value: unknown): value is UserCategory {
  return typeof value === 'string' && (Object.values(userCategories) as string[]).includes(value)
}

export function isStateMachineClaim(value: unknown): value is StateMachineClaim {
  if (typeof value !== 'object' || value == null) {
    return false
  }
  const valueAsPartial = value as Partial<StateMachineClaim>
  return (
    typeof valueAsPartial.state === 'string' &&
    (typeof valueAsPartial.context === 'object' || typeof valueAsPartial.context === 'undefined')
  )
}
