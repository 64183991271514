import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import {
  CartFragment,
  SetCartAttributesDocument,
  SetCartAttributesMutationVariables,
} from '../../../../graphql/magento'

export type UseCartUpdateAttributes = {
  updateCartAttributes: (options: UpdateCartAttributesOptions) => Promise<CartFragment | undefined>
}

export type UpdateCartAttributesOptions = {
  token: string
  cartId: string
  cartAttributes: CartUpdateAttributesVariables
}

export type CartUpdateAttributesVariables = Omit<SetCartAttributesMutationVariables, 'cartId'>

export const useCartUpdateAttributes = (): UseCartUpdateAttributes => {
  const [setCartAttributesMutation] = useMutation(SetCartAttributesDocument)

  const updateCartAttributes = useCallback(
    async ({
      token,
      cartId,
      cartAttributes,
    }: UpdateCartAttributesOptions): Promise<CartFragment | undefined> => {
      const { data } = await setCartAttributesMutation({
        variables: { cartId, ...cartAttributes },
        context: { token },
      })
      return data?.setCartAttributes?.cart
    },
    [setCartAttributesMutation]
  )

  return { updateCartAttributes }
}
