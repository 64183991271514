import * as R from 'ramda'
import { useCallback } from 'react'
import useLogAndCaptureError from '../../../../hooks/useLogAndCaptureError'
import { UseCartContext } from '../../../cart'
import { UseCartRemoveCoupon, useCartRemoveCoupon } from '../../../cart/hooks/useCartRemoveCoupon'
import { findRootPromoMatchingCode } from '../../../cart/hooks/usePromoCodeUtilities/findRootPromo'
import { cartCoupons } from '../../../cart/utils'
import { setDispensaryCartState } from '../../events/cart'
import { useDispensaryPromosAsync } from '../useDispensaryPromosAsync'

export type UseRemoveDispensaryPromoCodeFromCart = [
  removeDispensaryPromoCodeFromCart: RemoveDispensaryPromoCodeFromCart,
  result: UseCartRemoveCoupon['1']
]

export type RemoveDispensaryPromoCodeFromCart = (options: {
  shouldClearDispensaryPromoCode: boolean
}) => Promise<void | undefined>

export interface UseRemoveDispensaryPromoCodeFromCartOptions {
  cartContext: UseCartContext
}

export const useRemoveDispensaryPromoCodeFromCart = ({
  cartContext,
}: UseRemoveDispensaryPromoCodeFromCartOptions): UseRemoveDispensaryPromoCodeFromCart => {
  const { loadCart } = cartContext
  const { getDispensaryPromos } = useDispensaryPromosAsync()
  const [removeCouponFromCart, result] = useCartRemoveCoupon()
  const { error } = result

  useLogAndCaptureError(error)

  const removeDispensaryPromoCodeFromCart = useCallback<RemoveDispensaryPromoCodeFromCart>(
    async ({ shouldClearDispensaryPromoCode }): Promise<void> => {
      if (!shouldClearDispensaryPromoCode) {
        return
      }
      const cart = await loadCart()
      if (!cart) {
        return
      }
      const appliedCoupons = cartCoupons(cart)
      if (!appliedCoupons) {
        return
      }
      const dispensaryPromos = await getDispensaryPromos()
      const rootPromos = dispensaryPromos?.getEncodedDispensaryPromos?.rootPromos
      if (!rootPromos) {
        return
      }
      const findRootPromo = findRootPromoMatchingCode(rootPromos)

      const appliedRootPromos = await appliedCoupons.reduce(
        async (acc: Promise<string[]>, coupon) => {
          if (!coupon) return acc
          const rootPromo = await findRootPromo(coupon.code)
          if (!rootPromo) return acc
          const awaitedAcc = await acc
          awaitedAcc.push(coupon.code)
          return awaitedAcc
        },
        Promise.resolve([])
      )

      if (!appliedRootPromos.length) {
        return
      }

      setDispensaryCartState({
        type: 'cart_remove_promo_code_start',
        promoCodes: appliedRootPromos,
      })

      await appliedRootPromos
        .reduce(async (acc: Promise<string[]>, code) => {
          const awaitedAcc = await acc
          await removeCouponFromCart({
            variables: {
              couponCode: code,
            },
          })
          awaitedAcc.push(code)
          return awaitedAcc
        }, Promise.resolve([]))
        .then(
          R.tap((removedRootPromos) =>
            setDispensaryCartState({
              type: 'cart_remove_promo_code_complete',
              promoCodes: removedRootPromos,
            })
          )
        )
    },
    [loadCart, getDispensaryPromos, removeCouponFromCart]
  )

  return [removeDispensaryPromoCodeFromCart, result]
}
