import * as R from 'ramda'
import { checkCryptoApiAvailable, encodeUtf8ByteArray } from './util'

const sha256Digest = (message: Uint8Array): Promise<ArrayBuffer> =>
  checkCryptoApiAvailable().then(() => window.crypto.subtle.digest('SHA-256', message))

const hexStringFromBuffer = (buffer: ArrayBuffer): string =>
  Array.from(new Uint8Array(buffer))
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')

export const hashCode = R.pipe(encodeUtf8ByteArray, sha256Digest, R.andThen(hexStringFromBuffer))
