import { ParsedQuery } from 'query-string'

import { isNotNull } from '../../../../utils/collectionTools'
import { parseStringParam, QueryParamRaw } from '../../../../utils/url'
import { EScriptAutoShipInterval, eScriptAutoShipIntervals } from '../../config'
import { EScriptProduct } from '../../types'
import { EScript20230411Operation, eScript20230411Operations } from './config'
import { EScript20230411Params, EScript20230411Version } from './types'

const isValidVersion = (version: string | undefined): version is EScript20230411Version =>
  // TODO check for any date valid after the version date
  version === '2023-04-11'

const isValidOperation = (operation: string | undefined): operation is EScript20230411Operation =>
  !!operation && eScript20230411Operations.includes(operation as EScript20230411Operation)

const isValidAutoShipInterval = (
  autoShipInterval: string | undefined
): autoShipInterval is EScriptAutoShipInterval =>
  !!autoShipInterval &&
  eScriptAutoShipIntervals.includes(autoShipInterval as EScriptAutoShipInterval)

const parseVersionParam = (version: string | undefined): EScript20230411Version =>
  isValidVersion(version) ? version : '2023-04-11'

const parseOperationParam = (operation: string | undefined): EScript20230411Operation | undefined =>
  isValidOperation(operation) ? operation : undefined

const isValidProductQuery = (productsQuery: string): boolean => {
  // regex for one product /^\(([^*()]+)(\*[^*()]+)*\)$/
  // regex for multiple products is the same but wrapped in parentheses and with a + at the end
  return /^(\(([^*()]+)(\*[^*()]+)*\))+$/.test(productsQuery)
}

const parseProduct = (singleProductSlice: string): EScriptProduct | undefined => {
  const [sku, quantity, autoShip] = singleProductSlice.slice(1, -1).split('*')
  return {
    productSku: sku,
    productQuantity: quantity && /^\d+$/.test(quantity) ? Number(quantity) : 1,
    productAutoShip: isValidAutoShipInterval(autoShip) ? autoShip : undefined,
  }
}

const parseProducts = (productsParamValue: string): EScriptProduct[] | undefined => {
  if (!isValidProductQuery(productsParamValue)) {
    return undefined
  }
  const regex = /\(([^()]+)\)/g // matches everything between parenthesis
  const products = productsParamValue.match(regex)
  return products ? products.map(parseProduct).filter(isNotNull) : undefined
}

const parseProductsParam = (paramValue: QueryParamRaw): EScriptProduct[] | undefined => {
  const allProductsQuery = Array.isArray(paramValue)
    ? paramValue.reduce((acc, curr) => acc + curr, '')
    : paramValue

  return allProductsQuery ? parseProducts(allProductsQuery) : undefined
}

export const getEScript20230411ParamsFromQuery = (
  queryParams: ParsedQuery
): EScript20230411Params => {
  const {
    v: version,
    op: operation,
    promocode: promoCode,
    products,
    ec: eScriptCampaign,
  } = queryParams

  return {
    version: parseVersionParam(parseStringParam(version)),
    operation: parseOperationParam(parseStringParam(operation)),
    promoCode: parseStringParam(promoCode),
    products: parseProductsParam(products),
    eScriptCampaign: parseStringParam(eScriptCampaign),
  }
}

export const hasValidEScript20230411ProductsParam = (
  products: EScript20230411Params['products']
): boolean =>
  !!(
    products &&
    products.length > 0 &&
    products.every((product) => product.productQuantity > 0) &&
    products.every((product) => product.productSku)
  )
