import * as R from 'ramda'
import { GetEncodedDispensaryPromosQuery } from '../../../../graphql/camel'
import { hashCode } from './hashCode'

export const findRootPromoMatchingCode = (
  rootPromos: NonNullable<
    GetEncodedDispensaryPromosQuery['getEncodedDispensaryPromos']
  >['rootPromos']
): ((
  text: string
) => Promise<
  | NonNullable<GetEncodedDispensaryPromosQuery['getEncodedDispensaryPromos']>['rootPromos'][number]
  | undefined
>) =>
  R.pipe(
    hashCode,
    R.andThen((inputCodeHash) =>
      rootPromos.find((rootPromo) => rootPromo?.rootCodeHash === inputCodeHash)
    )
  )
