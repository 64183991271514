import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  of,
  ReplaySubject,
  switchMap,
} from 'rxjs'

import { DispensaryExternalEffectNotify } from './types'

export type DispensaryExternalEffectNotifyWithAck = DispensaryExternalEffectNotify & {
  /**
   * Acknowledge (dismiss) the notification.
   */
  ack: () => void
}

/**
 * Replay subject used to hold a `notify` effect until it can be consumed (and acknowledged).
 */
const latestExternalNotifyReplay$ = new ReplaySubject<DispensaryExternalEffectNotify | null>(1)

/**
 * Behavior subject used to hold the latest acknowledged `notify` effect.
 */
const latestExternalNotifyAck$ = new BehaviorSubject<DispensaryExternalEffectNotify | null>(null)

/**
 * Observable emits the latest `notify` effect that has not been acknowledged. This should be
 * consumed by notification components.
 */
export const latestExternalNotify$: Observable<DispensaryExternalEffectNotifyWithAck | null> = latestExternalNotifyReplay$.pipe(
  distinctUntilChanged(),
  switchMap((effect) =>
    effect == null
      ? of(effect)
      : // emit effect if it has not been acknowledged
        latestExternalNotifyAck$.pipe(
          map((lastAcked) =>
            lastAcked === effect
              ? null
              : {
                  ...effect,
                  // include `ack` method to dismiss the notification
                  ack: () => latestExternalNotifyAck$.next(effect),
                }
          )
        )
  )
)

/**
 * Given a `notify` external effect, return an observable that stores the effect for use by
 * notification components.
 */
export const handleExternalNotifyEffect = (
  effect: DispensaryExternalEffectNotify
): Observable<never> =>
  new Observable((subscriber) => {
    latestExternalNotifyReplay$.next(effect)
    subscriber.complete()
  })

/**
 * Clear the latest `notify` effect.
 */
export const clearExternalNotifyEffect = (): Observable<never> =>
  new Observable((subscriber) => {
    latestExternalNotifyReplay$.next(null)
    subscriber.complete()
  })
