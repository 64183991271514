export const isNotNull = <T>(value: T): value is NonNullable<T> => value != null

export const isNotNullAnd = <T>(f: (value: T) => boolean) => (
  value: T | null | undefined
): value is T => value != null && f(value)

export const findAsyncSeq = <T>(predicate: (t: T) => Promise<boolean>) => async (
  array: ReadonlyArray<T>
): Promise<T | undefined> => {
  for (const t of array) {
    if (await predicate(t)) {
      return t
    }
  }
  return undefined
}

/**
 * Reduce (or map) an iterable (e.g. Set, Map)
 *
 * May or may not be irritable
 */
export const reduceIterable = <A, B>(initial: B, reducer: (acc: B, val: A) => B) => (
  iterable: Iterable<A>
): B => {
  let acc = initial
  for (const item of iterable) {
    acc = reducer(acc, item)
  }
  return acc
}

/**
 * Reduce (or map) a Set
 */
export const reduceSet: <A, B>(
  initial: B,
  reducer: (acc: B, val: A) => B
) => (set: Set<A>) => B = reduceIterable
