import { useCallback } from 'react'
import { GetDispensaryByCodeQueryVariables } from '../../../graphql/camel'
import { usePromisifyLazy } from '../../../hooks/usePromisifyLazy'
import { Dispensary } from '../types'
import { getDispensaryFromData } from '../utils'
import { useDispensaryByCodeLazy } from './useDispensaryByCodeLazy'

export type UseDispensaryByCodeAsync = {
  getDispensaryByCode: (
    options: GetDispensaryByCodeQueryVariables
  ) => Promise<Dispensary | undefined>
}

export const useDispensaryByCodeAsync = (): UseDispensaryByCodeAsync => {
  const [doGetDispensaryByCode, { loading, error, data, called }] = useDispensaryByCodeLazy()

  const { load } = usePromisifyLazy({
    doRequest: doGetDispensaryByCode,
    called,
    loading,
    error: typeof error === 'string' ? new Error(error) : error,
    data,
  })

  const getDispensaryByCode = useCallback(
    async (options: GetDispensaryByCodeQueryVariables): Promise<Dispensary | undefined> => {
      const userDispensary = await load(options)
      if (!userDispensary) {
        return undefined
      }
      return getDispensaryFromData(userDispensary)
    },
    [load]
  )

  return { getDispensaryByCode }
}
