export type QueryParamRaw = string[] | string | null | undefined

const separator = (url: string): string => (url.includes('?') ? '&' : '?')

export const addQueryParamsToUrl = (url: string) => (params: string[]) =>
  params.length > 0 ? `${url}${separator(url)}${params.join('&')}` : url

export const createQueryParam = (key: string, value: string | null): string | null =>
  value ? `${key}=${encodeURIComponent(value)}` : null

export const addFragmentToUrl = (url: string, fragment: string): string => `${url}#${fragment}`

export const parseStringParam = <T extends string = string>(
  paramValue: QueryParamRaw,
  defaultValue?: T,
  validator: (value: T) => boolean = (value) => !!value
): T | undefined => {
  if (typeof paramValue !== 'string') {
    return defaultValue
  }
  const value = paramValue.trim() as T
  if (!validator(value)) {
    return defaultValue
  }
  return value
}

export const parseNumberParam = (
  paramValue: QueryParamRaw,
  defaultValue?: number,
  validator: (value: number) => boolean = (value) => !isNaN(value)
): number | undefined => {
  const number = Number(paramValue)
  if (!validator(number)) {
    return defaultValue
  }
  return number
}

export const parseArrayParam = <T>(
  paramValue: QueryParamRaw,
  mapper: (valueRaw: string) => T | undefined,
  filter: (value: T | undefined) => boolean = (value) => typeof value !== 'undefined'
): T[] => {
  const valuesArray = Array.isArray(paramValue) ? paramValue : (paramValue || '').split(',')
  return valuesArray
    .map((valueRaw) => {
      return mapper(valueRaw)
    })
    .filter<T>((value): value is T => {
      return filter(value)
    })
}
export const parseStringArrayParam = <T extends string = string>(
  paramValue: QueryParamRaw,
  filter?: (value: T | undefined) => boolean
): T[] => {
  return parseArrayParam(paramValue, (valueRaw) => parseStringParam<T>(valueRaw, '' as T), filter)
}
