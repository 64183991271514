import { CompletedCartsListEntry, CompletedCartWithUserId } from './types'

export function tryJsonParse(value: string): unknown | undefined {
  try {
    return JSON.parse(value)
  } catch (err: unknown) {
    console.error('Error parsing value as JSON:', value)
    return undefined
  }
}

function isCompletedCartsListEntry(value: unknown): value is CompletedCartsListEntry {
  if (typeof value !== 'object' || value == null) {
    return false
  }
  const { cartId, completedAt, storageKey } = value as Record<string, unknown>
  return (
    typeof cartId === 'string' && typeof completedAt === 'string' && typeof storageKey === 'string'
  )
}

// lightweight type guard for values parsed from local storage
export function isCompletedCartWithUserId(value: unknown): value is CompletedCartWithUserId {
  return (
    typeof value === 'object' &&
    value != null &&
    'cart' in value &&
    'completedAt' in value &&
    'order' in value &&
    'userId' in value
  )
}

export function reduceCompletedCartsListEntry(
  acc: Array<CompletedCartsListEntry>,
  value: unknown
): Array<CompletedCartsListEntry> {
  if (!isCompletedCartsListEntry(value)) {
    return acc
  }
  acc.push(value)
  return acc
}
