import { GetCustomerCartQuery, VirtualProductCartItemInput } from '../../graphql/magento'
import { Maybe } from '../../utils/types'
import { defaultProductLabelDesignFeeSKU } from './config'
import { CartItemVirtualProduct } from './state/types'
import { CartItem, CartItems } from './types'

export enum OrderType {
  SingleOrder = 1,
  AutoShip = 2,
}

export const stripHtmlTags = ({ text }: { text: string }): string => text.replace(/<(.|\n)*?>/g, '')

export const itemDisplayNameVariantInfo = ({
  itemAttributes,
}: {
  itemAttributes: CartItem['item_attributes']
}): string => {
  if (itemAttributes?.flavor_size) {
    return `, ${itemAttributes.flavor_size}`
  }

  if (itemAttributes?.flavor) {
    return `, ${itemAttributes.flavor}`
  }

  if (itemAttributes?.dosage) {
    return `, ${itemAttributes.dosage}`
  }

  return ''
}

export const shouldShowCaliforniaProp65Asterisks = ({
  item,
  shippingAddress,
}: {
  item: CartItem
  shippingAddress?: GetCustomerCartQuery['customerCart']['shipping_addresses'][0]
}): boolean =>
  !!shippingAddress &&
  // prop_sixty_five value is null, 0, or 1
  !!item.item_attributes?.prop_sixty_five &&
  shippingAddress.country.code === 'US' &&
  shippingAddress.region?.code === 'CA'

export const shouldShowCaliforniaProp65Warning = ({
  items,
  shippingAddress,
}: {
  items: CartItems
  shippingAddress?: GetCustomerCartQuery['customerCart']['shipping_addresses'][0]
}): boolean =>
  !!shippingAddress &&
  shippingAddress.country.code === 'US' &&
  shippingAddress.region?.code === 'CA' &&
  // prop_sixty_five value is null, 0, or 1
  items.some((item) => !!item?.item_attributes?.prop_sixty_five)

export const cartItemDisplayName = ({
  item,
  shippingAddress = null,
}: {
  item: CartItem
  shippingAddress?: GetCustomerCartQuery['customerCart']['shipping_addresses'][0]
}): string => {
  const { item_attributes: itemAttributes } = item
  return `${shouldShowCaliforniaProp65Asterisks({ item, shippingAddress }) ? '** ' : ''}${
    item.product.name
  }${itemDisplayNameVariantInfo({ itemAttributes })}${
    itemAttributes?.size ? ` - ${itemAttributes.size}` : ''
  }`.toUpperCase()
}

export function isProductInStock(
  itemVariant: Pick<NonNullable<CartItem['product']>, 'stock_status'>
): boolean {
  return itemVariant.stock_status === 'IN_STOCK'
}

// Some of the product attributes are HTML containing paragraphs with only
// a non-breaking space inside in an apparent attempt to handle spacing. Because
// this is only done for some of the products it can cause really large gaps
// between paragraphs on some of the PDPs but not others so to make things
// consistent these 'empty' p tags must be removed.
export const removeEmptyParagraphs = ({ htmlString }: { htmlString: string }): string =>
  htmlString.replace(/<p>\s*<\/p>/gi, '')

export const truncatedNonHtmlText = ({
  text,
  wordCount = 5,
}: {
  text: string
  wordCount?: number
}): string => stripHtmlTags({ text }).split(' ').slice(0, wordCount).join(' ')

export const parseItemIdAsNumber = (item: CartItem): number => {
  const itemId = Number(item.id)
  if (Number.isNaN(itemId)) {
    throw new Error(`Unable to parse item id "${item.id}" as number`)
  }
  return itemId
}

// Excludes items dependent on other items' inclusion in the cart from cart total quantity
export const totalQuantityExcludingDependents = (
  cartItems: Maybe<CartItems>,
  totalCount: number
): number =>
  Math.max(
    0,
    totalCount -
      (cartItems ?? []).reduce(
        (acc: number, item) => (isItemLabelDesignFee(item) ? acc + item.quantity : acc),
        0
      )
  )

export const totalItemsDisplay = (quantity: number): string =>
  `${quantity} Item${quantity === 1 ? '' : 's'}`

export const isVirtualCartItem = (item: Maybe<CartItem>): item is CartItemVirtualProduct =>
  !!item && item.__typename === 'VirtualCartItem'

export const isItemLabelDesignFee = (item: Maybe<CartItem>): item is CartItemVirtualProduct =>
  isVirtualCartItem(item) && item.product.sku === defaultProductLabelDesignFeeSKU

type CartItemSelectedCustomizableOption = NonNullable<
  NonNullable<CartItemVirtualProduct['customizable_options']>[number]
>

const isSelectedCustomizableOption = (
  option: unknown
): option is CartItemSelectedCustomizableOption =>
  option !== null &&
  typeof option === 'object' &&
  '__typename' in option &&
  option.__typename === 'SelectedCustomizableOption'

export const labelDesignItemValues = (
  item: CartItemVirtualProduct
): { label: string; value: string }[] => {
  const customizableOptions = item.customizable_options?.filter(isSelectedCustomizableOption)
  return customizableOptions
    ? customizableOptions
        .filter((customOption) => customOption.label !== 'Product')
        .sort((a, b) => (a.sort_order < b.sort_order ? -1 : 1))
        .reduce((acc: { label: string; value: string }[], customOption, index) => {
          acc.push({
            label: customOption.label,
            value: customOption.values[0]?.value || '',
          })
          return acc
        }, [])
    : []
}

/**
 * Search virtual cart item's customizable options for option with label `Product` and return value (if any)
 */
export const productOptionValueFromCartItem = (
  item: CartItemVirtualProduct
): string | undefined => {
  const productOption = item.customizable_options?.find((option) => option?.label === 'Product')
  return productOption?.values?.[0]?.value
}

/**
 * Search cart items for corresponding label design item
 */
export const correspondingLabelDesignItem = (item: CartItem, items: CartItems): Maybe<CartItem> =>
  items?.find(
    (cartItem) =>
      isItemLabelDesignFee(cartItem) &&
      isVirtualCartItem(cartItem) &&
      cartItem.customizable_options?.find(
        (customOption) => !!customOption?.values.find((value) => value?.value === item.product.sku)
      )
  )

export interface CartItemAndLabelDesignFee {
  item: CartItem
  designFeeItem: CartItemVirtualProduct | undefined
}

function isCartItemAndLabelDesignFee(
  partial: Partial<CartItemAndLabelDesignFee>
): partial is CartItemAndLabelDesignFee {
  return !!partial.item
}

/**
 * Collect items with paired label design fee (for PL)
 */
export const collectItemAndDesignFeePairs = (
  items: CartItems
): Map<string, CartItemAndLabelDesignFee> => {
  const partials = items.reduce(
    (acc: Map<string, Partial<CartItemAndLabelDesignFee>>, cartItem) => {
      if (isVirtualCartItem(cartItem) && isItemLabelDesignFee(cartItem)) {
        const productSkuValue = cartItem.customizable_options?.find(
          (customOption) => customOption?.label === 'Product'
        )?.values?.[0]?.value
        if (!productSkuValue) {
          return acc
        }
        const existingPair = acc.get(productSkuValue)
        if (existingPair) {
          existingPair.designFeeItem = cartItem
        } else {
          acc.set(productSkuValue, {
            item: undefined,
            designFeeItem: cartItem,
          })
        }
      } else {
        const sku = cartItem?.product?.sku
        // shouldn't happen
        if (!sku) {
          return acc
        }
        const existingPair = acc.get(sku)
        if (existingPair) {
          existingPair.item = cartItem
        } else {
          acc.set(sku, {
            item: cartItem,
            designFeeItem: undefined,
          })
        }
      }
      return acc
    },
    new Map<string, Partial<CartItemAndLabelDesignFee>>()
  )

  // filter out any partials without a primary cart item
  const result = new Map<string, CartItemAndLabelDesignFee>()
  for (const [sku, partial] of partials.entries()) {
    if (isCartItemAndLabelDesignFee(partial)) {
      result.set(sku, partial)
    }
  }
  return result
}

export const labelDesignFeeCartItemInput = ({
  forSku,
  desiredName,
  additionalDetails,
}: {
  forSku: string
  desiredName: string
  additionalDetails?: string
}): VirtualProductCartItemInput => ({
  data: {
    sku: defaultProductLabelDesignFeeSKU,
    quantity: 1,
  },
  customizable_options: [
    {
      id: 1,
      value_string: forSku,
    },
    {
      id: 2,
      value_string: desiredName,
    },
    ...(additionalDetails
      ? [
          {
            id: 3,
            value_string: additionalDetails,
          },
        ]
      : []),
  ],
})
