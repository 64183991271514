import { useEffect } from 'react'
import { filter, switchMap } from 'rxjs/operators'

import { latestAccessTokenFactory$, latestAuthState$ } from '../../../auth/state'
import { actions, UseCartGetReducer } from '../useCartGetReducer'

const accessToken$ = latestAuthState$.pipe(
  filter(({ isLoading }) => !isLoading),
  switchMap(() => latestAccessTokenFactory$),
  switchMap((getAccessToken) => getAccessToken())
)

export const useAuthActions = ({ reducer }: { reducer: UseCartGetReducer }): void => {
  const [state, dispatch] = reducer

  // dispatch session actions
  useEffect(() => {
    if (state.type !== 'none') {
      return
    }
    const subscription = accessToken$.subscribe({
      next: (token) => dispatch(token ? actions.loggedIn() : actions.loggedOut()),
      error: () => dispatch(actions.loggedOut()),
    })
    return () => subscription.unsubscribe()
  }, [dispatch, state.type])
}
