import { isEmpty, isEqual } from 'lodash'
import * as R from 'ramda'
import { useCallback } from 'react'

import useLogAndCaptureError from '../../../../hooks/useLogAndCaptureError'
import type { ResolveType } from '../../../../utils/types'
import {
  SetCartAttributesVariables,
  useCartGetAttributes,
  UseCartSetAttributes,
  useCartSetAttributes,
} from '../../../cart'
import { EScriptParamsLoader } from '../../../escript/types'
import { setDispensaryCartState } from '../../events/cart'
import type { DispensaryContextValue } from '../../types'

export type UseUpdateCartAttributes = [
  updateCartAttributes: UpdateCartAttributes,
  result: UseCartSetAttributes['1']
]

export type UpdateCartAttributes = (options: {
  forceClearEScriptParams: boolean
  shouldClearDispensaryPromoCode: boolean
}) => Promise<ResolveType<ReturnType<UseCartSetAttributes['0']>> | undefined>

export interface UseUpdateCartAttributesOptions {
  dispensaryContext: DispensaryContextValue | null
  eScriptParamsLoader: EScriptParamsLoader
}

/**
 * Returns an object with the given attributes set to empty strings,
 * useful for clearing attributes from the Magento cart
 * @param attributes
 */
function clearCartAttributes<T extends keyof SetCartAttributesVariables>(
  ...attributes: T[]
): { [P in T]: '' } {
  return attributes.reduce((acc, key) => ({ ...acc, [key]: '' }), {} as { [P in T]: '' })
}

const emptyWellWorldAttributes = clearCartAttributes(
  'wellWorldId',
  'wellWorldStoreId',
  'wellWorldPracticeId',
  'wellWorldPractitionerId'
)

const emptyEScriptCampaign = clearCartAttributes('eScriptCampaign')

export const useUpdateCartAttributes = ({
  dispensaryContext,
  eScriptParamsLoader,
}: UseUpdateCartAttributesOptions): UseUpdateCartAttributes => {
  const { loadParams } = eScriptParamsLoader

  const [setCartAttributes, result] = useCartSetAttributes()
  const { loadCartAttributes } = useCartGetAttributes()
  const { error } = result

  useLogAndCaptureError(error)

  const getPractitionerCodes = useCallback((): {
    isInvalid: boolean
    practitionerCode: string
    wellWorldPractitionerCode: string
  } => {
    if (!dispensaryContext) {
      return { isInvalid: false, practitionerCode: '', wellWorldPractitionerCode: '' }
    }
    return {
      isInvalid: dispensaryContext.isInvalid,
      practitionerCode: dispensaryContext.practitionerCodeForOrder,
      wellWorldPractitionerCode: dispensaryContext.practitionerCodeForWellWorldOrder,
    }
  }, [dispensaryContext])

  const updateCartAttributes = useCallback<UpdateCartAttributes>(
    async ({ forceClearEScriptParams }) => {
      // load cart attribute params and load current cart attributes
      const [
        { cartAttributes },
        { cartAttributes: currentCartAttributes, isWellWorldCart },
      ] = await Promise.all([loadParams(), loadCartAttributes()])

      const {
        practitionerCode: currentPractitionerCode,
        eScriptCampaign: currentEScriptCampaign,
        ...currentWellWorldAttributes
      } = currentCartAttributes
      const { isInvalid, practitionerCode, wellWorldPractitionerCode } = getPractitionerCodes()

      function practitionerCodeAttribute(): Pick<SetCartAttributesVariables, 'practitionerCode'> {
        // if dispensary URL is invalid, and WW params present (or set in cart), update
        // practitioner code if not equal to the WW practitioner code
        // - only applicable to invalid dispensary URLs since the fallback/invalid URL codes vary
        if (isInvalid && isWellWorldCart) {
          return {
            ...(currentPractitionerCode !== wellWorldPractitionerCode && {
              practitionerCode: wellWorldPractitionerCode,
            }),
          }
        }

        // if practitioner code in cart does not match the dispensary's practitioner code, update
        return {
          ...(currentPractitionerCode !== practitionerCode && {
            practitionerCode,
          }),
        }
      }

      function wellWorldAttributes(): Pick<
        SetCartAttributesVariables,
        'wellWorldId' | 'wellWorldPracticeId' | 'wellWorldPractitionerId' | 'wellWorldStoreId'
      > {
        if (!cartAttributes) {
          // if requested or if practitioner code in cart differs from dispensary's practitioner
          // code, clear WW attributes
          return forceClearEScriptParams ||
            (currentPractitionerCode !== practitionerCode &&
              currentPractitionerCode !== wellWorldPractitionerCode)
            ? emptyWellWorldAttributes
            : {}
        }

        if (cartAttributes.type !== 'wellWorld') {
          return currentWellWorldAttributes.wellWorldId ||
            currentWellWorldAttributes.wellWorldStoreId ||
            currentWellWorldAttributes.wellWorldPracticeId ||
            currentWellWorldAttributes.wellWorldPractitionerId
            ? emptyWellWorldAttributes
            : {}
        }

        // if new cart attributes are WW, then update cart attributes as needed
        const {
          wellWorldId,
          wellWorldStoreId,
          wellWorldPracticeId,
          wellWorldPractitionerId,
        } = cartAttributes.attributes

        const wellWorldAttributesFromParams = {
          wellWorldId: wellWorldId || '',
          wellWorldStoreId: wellWorldStoreId || '',
          wellWorldPracticeId: wellWorldPracticeId || '',
          wellWorldPractitionerId: wellWorldPractitionerId || '',
        }

        return isEmpty(currentWellWorldAttributes) ||
          !isEqual(currentWellWorldAttributes, wellWorldAttributesFromParams)
          ? wellWorldAttributesFromParams
          : {}
      }

      function eScriptCampaignAttribute() {
        if (!cartAttributes) {
          return forceClearEScriptParams ? emptyEScriptCampaign : {}
        }

        if (cartAttributes.type !== 'eScriptCampaign') {
          return emptyEScriptCampaign
        }

        return cartAttributes.attributes.eScriptCampaign !== currentEScriptCampaign
          ? { eScriptCampaign: cartAttributes.attributes.eScriptCampaign }
          : {}
      }

      const variables: SetCartAttributesVariables = {
        ...practitionerCodeAttribute(),
        ...eScriptCampaignAttribute(),
        ...wellWorldAttributes(),
        // ...(shouldClearDispensaryPromoCode && { dispensaryCode: '' }), // TODO sc-46945
      }

      if (isEmpty(variables)) {
        return
      }
      setDispensaryCartState({ type: 'cart_update_attributes_start', ...variables })
      return setCartAttributes({ variables }).then(
        R.tap(() =>
          setDispensaryCartState({ type: 'cart_update_attributes_complete', ...variables })
        )
      )
    },
    [loadParams, getPractitionerCodes, loadCartAttributes, setCartAttributes]
  )

  return [updateCartAttributes, result]
}
