import { useCallback } from 'react'

import { getCartToastActions } from '../../../components/cart/CartToastActions'
import { useUniqueToast } from '../../toasts/hooks/useUniqueToast'

export interface ShowSuccessToastOptions {
  skus?: string[]
}

export interface ShowFailureToastOptions {
  skus?: string[]
  additionalMessage?: string
}

export interface ShowRemovedToastOptions {
  skus?: string[]
}

export interface UseAddToCartToast {
  showSuccessToast: (options: ShowSuccessToastOptions) => void
  showFailureToast: (options: ShowFailureToastOptions) => void
  showRemovedToast: (options: ShowRemovedToastOptions) => void
}

const getSuccessMessage = (skus?: string[]): string => {
  if (!skus || skus.length === 0) {
    return 'Successfully added to cart.'
  }
  if (skus.length > 3) {
    const [first, second, ...rest] = skus
    return `${first}, ${second} and ${rest.length} more successfully added to cart.`
  }
  return `${skus.join(', ')} successfully added to cart.`
}

const getFailureMessage = (skus?: string[]): string => {
  if (!skus || skus.length === 0) {
    return 'Failed to add to cart.'
  }
  return `${skus.join(', ')} failed to add to cart.`
}

const getRemovedMessage = (skus?: string[]) =>
  !skus || skus.length === 0
    ? 'An item in your cart cannot be purchased at this time and has been removed.'
    : `${skus.join(', ')} cannot be purchased at this time and has been removed from your cart.`

export const useAddToCartToast = (): UseAddToCartToast => {
  const { showToast, hideToastById } = useUniqueToast({ toastIdPrefix: 'addtocart-' })

  const showSuccessToast = useCallback(
    ({ skus }: ShowSuccessToastOptions) => {
      const message = getSuccessMessage(skus)
      showToast(message, {
        variant: 'success',
        action: getCartToastActions({ dismissToast: hideToastById }),
      })
    },
    [hideToastById, showToast]
  )

  const showFailureToast = useCallback(
    ({ skus, additionalMessage }: ShowFailureToastOptions) => {
      const message = `${getFailureMessage(skus)}${
        additionalMessage ? ` ${additionalMessage}` : ''
      }`
      showToast(message, {
        variant: 'warning',
        persist: true,
        action: getCartToastActions({ dismissToast: hideToastById }),
      })
    },
    [hideToastById, showToast]
  )

  const showRemovedToast = useCallback(
    ({ skus }: ShowRemovedToastOptions) => {
      showToast(getRemovedMessage(skus), {
        variant: 'warning',
        persist: true,
        action: getCartToastActions({ dismissToast: hideToastById }),
      })
    },
    [hideToastById, showToast]
  )

  return { showSuccessToast, showFailureToast, showRemovedToast }
}
