import { isApolloError } from '@apollo/client'
import * as R from 'ramda'
import { useCallback } from 'react'

import { GetEncodedDispensaryPromosQuery } from '../../../../graphql/camel'
import useLogAndCaptureError from '../../../../hooks/useLogAndCaptureError'
import { usePromisify } from '../../../../hooks/usePromisify'
import { anyTrue } from '../../../../utils/ramda'
import type { ResolveType } from '../../../../utils/types'
import { UseCartApplyCoupon, useCartApplyCoupon } from '../../../cart/hooks/useCartApplyCoupon'
import {
  translateCodeForDispensary,
  translateCodeForGlobal,
} from '../../../cart/hooks/usePromoCodeUtilities/translateCode'
import { setEScriptPromoUsed } from '../../../escript/state'
import { EScriptParamsLoader } from '../../../escript/types'
import { setDispensaryCartState } from '../../events/cart'
import { useDispensaryPromos } from '../useDispensaryPromos'

export type UseApplyPromoCodeToCart = [
  applyPromoCodeToCart: () => Promise<ResolveType<ReturnType<UseCartApplyCoupon['0']>> | undefined>,
  result: UseCartApplyCoupon['1']
]

export interface UseApplyPromoCodeToCartOptions {
  eScriptParamsLoader: EScriptParamsLoader
}

const isCouponCodeError = (err: unknown): boolean =>
  err instanceof Error &&
  isApolloError(err) &&
  err.graphQLErrors.some((graphqlError) => graphqlError.message.includes('coupon code'))

const isHigherDiscountError = (err: unknown): boolean =>
  err instanceof Error &&
  isApolloError(err) &&
  err.graphQLErrors.some((graphqlError) => graphqlError.message.includes('higher discount'))

export const isIgnorablePromoCodeError = anyTrue(isCouponCodeError, isHigherDiscountError)

const translateDispensaryPromoCode = (
  loadPromosPromise: Promise<GetEncodedDispensaryPromosQuery | undefined>
) => async (promoCode: string): Promise<string> => {
  const dispensaryPromos = (await loadPromosPromise)?.getEncodedDispensaryPromos
  const translateCodeResult = await (!dispensaryPromos
    ? translateCodeForGlobal
    : translateCodeForDispensary(dispensaryPromos.promos))(promoCode)

  return translateCodeResult._tag === 'translated'
    ? translateCodeResult.translatedCode
    : translateCodeResult.inputCode
}

export const useApplyPromoCodeToCart = ({
  eScriptParamsLoader,
}: UseApplyPromoCodeToCartOptions): UseApplyPromoCodeToCart => {
  const { loadParams } = eScriptParamsLoader
  const [applyCouponToCart, result] = useCartApplyCoupon()
  const { error } = result

  useLogAndCaptureError(error, R.complement(isIgnorablePromoCodeError))

  const { load: loadDispensaryPromos } = usePromisify(useDispensaryPromos())

  const applyPromoCodeToCart = useCallback(async () => {
    const { promoCode: promoCodeRaw } = await loadParams()
    const promoCode = promoCodeRaw?.trim()
    if (!promoCode) {
      return
    }
    setEScriptPromoUsed({ code: promoCode })
    setDispensaryCartState({ type: 'cart_apply_promo_code_start', promoCode })
    const rootPromoCode = await translateDispensaryPromoCode(loadDispensaryPromos())(promoCode)
    return applyCouponToCart({ variables: { couponCode: rootPromoCode } }).then(
      R.tap(() => setDispensaryCartState({ type: 'cart_apply_promo_code_complete', promoCode }))
    )
  }, [applyCouponToCart, loadParams, loadDispensaryPromos])

  return [applyPromoCodeToCart, result]
}
