import { Auth0Context, Auth0Provider } from '@auth0/auth0-react'
import { ErrorBoundary } from '@sentry/gatsby'
import React from 'react'

import { isStorageDisabledError } from '../utils/storage'
import { Auth0ContextOverrideProvider } from './Auth0ContextOverrideProvider'

const stubFor = ({ message }: { message: string }) => (): never => {
  throw new Error(`Auth0 context not available: ${message}`)
}

const asyncStubFor = ({ message }: { message: string }) => <T,>(): Promise<T> =>
  Promise.reject(new Error(`Auth0 context not available: ${message}`))

const Auth0StubProvider: React.FC<{ error: Error; message: string }> = ({
  children,
  error,
  message,
}) => {
  const stub = stubFor({ message })
  const asyncStub = asyncStubFor({ message })

  return (
    <Auth0Context.Provider
      value={{
        error,
        isAuthenticated: false,
        isLoading: false,
        buildAuthorizeUrl: asyncStub,
        buildLogoutUrl: stub,
        getAccessTokenSilently: asyncStub,
        getAccessTokenWithPopup: asyncStub,
        getIdTokenClaims: asyncStub,
        loginWithRedirect: asyncStub,
        loginWithPopup: asyncStub,
        logout: stub,
        handleRedirectCallback: asyncStub,
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

export type Auth0WithFallbackProviderProps = React.ComponentProps<typeof Auth0Provider>

/**
 * Allow site to render if an error occurs initializing Auth0 provider.
 *
 * Auth0Provider attempts to access session storage directly (for transaction support),
 * and this triggers errors in all major browsers when local storage/cookes are blocked.
 * This fallback solution allows the site to render despite impaired functionality.
 */
const Auth0WithFallbackProvider: React.FC<Auth0WithFallbackProviderProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <ErrorBoundary
      fallback={({ error }) =>
        // stub Auth0 context if attempt to access session storage triggered error boundary
        isStorageDisabledError(error) ? (
          <Auth0StubProvider error={error} message="storage disabled">
            {children}
          </Auth0StubProvider>
        ) : (
          <Auth0Provider {...otherProps}>{children}</Auth0Provider>
        )
      }
    >
      <Auth0Provider {...otherProps}>
        <Auth0ContextOverrideProvider>{children}</Auth0ContextOverrideProvider>
      </Auth0Provider>
    </ErrorBoundary>
  )
}

export default Auth0WithFallbackProvider
