import { useEffect } from 'react'
import { garbageCollect } from '../gc'

export function useCompletedCartsGarbageCollect(): void {
  // cleanup old data in local storage
  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    let timeoutId: number | undefined = window.setTimeout(() => {
      garbageCollect()
      timeoutId = undefined
    }, 100)
    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId)
      }
    }
  }, [])
}
