const baseUrl = typeof window !== 'undefined' ? window.location.origin : ''

export const stringStartsWith = (
  str: string,
  prefix: string,
  { ignoreCase }: { ignoreCase: boolean } = { ignoreCase: false }
): boolean => {
  const strToCompare = ignoreCase ? str.toLowerCase() : str
  const prefixToCompare = ignoreCase ? prefix.toLowerCase() : prefix
  return strToCompare.lastIndexOf(prefixToCompare, 0) === 0
}

/** Returns provided path starting with forward slash "/", or an empty string */
export const normalizePath = (path?: string | null): string => {
  // add "/" if not already exists, and default to empty string
  return path ? (stringStartsWith(path, '/') ? path : `/${path}`) : ''
}

export const getUrlForNormalizedPath = (normalizedPath: string): string => {
  return `${baseUrl}${normalizedPath}`
}
