import {
  RemoveItemFromCartDocument,
  RemoveItemFromCartMutation,
  RemoveItemFromCartMutationVariables,
} from '../../../graphql/magento'
import { UseCartMutation, useCartMutation } from './useCartMutation'

export type UseCartRemoveProduct = [
  removeProductFromCart: CartMutation['0'],
  result: CartMutation['1']
]

type CartMutation = UseCartMutation<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>

export const useCartRemoveProduct = (): UseCartRemoveProduct => {
  return useCartMutation(RemoveItemFromCartDocument)
}
