import {
  ApplyCouponToCartDocument,
  ApplyCouponToCartMutation,
  ApplyCouponToCartMutationVariables,
} from '../../../graphql/magento'
import { useCartMutation, UseCartMutation } from './useCartMutation'

export type UseCartApplyCoupon = [applyCouponToCart: CartMutation['0'], result: CartMutation['1']]

type CartMutation = UseCartMutation<ApplyCouponToCartMutation, ApplyCouponToCartMutationVariables>

export const useCartApplyCoupon = (): UseCartApplyCoupon => {
  return useCartMutation(ApplyCouponToCartDocument)
}
