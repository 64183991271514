import { Auth0Context, Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react'
import React, { useMemo } from 'react'

import { usePageUrl } from '../lib/pages'

export const Auth0ContextOverrideProvider: React.FC = ({ children }) => {
  const { loginWithRedirect, ...auth0ContextRest } = useAuth0()
  const { getPageUrl } = usePageUrl()

  const contextValue = useMemo(
    (): Auth0ContextInterface => ({
      ...auth0ContextRest,
      loginWithRedirect: (options) =>
        loginWithRedirect({
          'ext-signup-url': getPageUrl('/register'),
          ...options,
        }),
    }),
    [auth0ContextRest, getPageUrl, loginWithRedirect]
  )

  return <Auth0Context.Provider value={contextValue}>{children}</Auth0Context.Provider>
}
