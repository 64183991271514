import { UserCategory } from '../../utils/userClaims'

export enum SanityUserRole {
  Anonymous = 'ANONYMOUS',
  Authenticated = 'AUTHENTICATED',
  CategoryEmployee = 'CAT_EMPLOYEE',
  CategoryOtherProfessional = 'CAT_OTHER_PROFESSIONAL',
  CategoryPatient = 'CAT_PATIENT',
  CategoryPractitioner = 'CAT_PRACTITIONER',
}

export const sanityUserRoles = Object.values(SanityUserRole)

export function isSanityUserRole(value: string | undefined | null): value is SanityUserRole {
  return !!value && sanityUserRoles.includes(value as SanityUserRole)
}

const userCategoryToRoleMap: Record<UserCategory, SanityUserRole> = {
  employee: SanityUserRole.CategoryEmployee,
  other_professional: SanityUserRole.CategoryOtherProfessional,
  patient: SanityUserRole.CategoryPatient,
  practitioner: SanityUserRole.CategoryPractitioner,
}

export function userRoleFromCategory(value: UserCategory): SanityUserRole {
  return userCategoryToRoleMap[value]
}
