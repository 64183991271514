import { Auth0ProviderOptions } from '@auth0/auth0-react'
import { navigate } from 'gatsby'
import { defaultIfEmpty, filter, map, of, switchMap, take } from 'rxjs'

import { addDispensaryBasePathToPath } from '../dispensaries'
import { dispensaryExternalEffect$ } from '../dispensaries/effects'
import {
  arbitrateRedirect$,
  mkAuth0RedirectSelection,
  redirectArbitrationStatus$,
  registerRedirectHandler,
} from './logging-in'

export const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = (appState) => {
  redirectArbitrationStatus$
    .pipe(
      // take the current status and complete
      take(1),
      switchMap((arbitrationEnabled) => {
        // arbitration is only used when the user is redirected to `/logging-in`
        if (!arbitrationEnabled) {
          return of(mkAuth0RedirectSelection())
        }
        registerRedirectHandler('auth0_redirect')
        return arbitrateRedirect$
      }),
      // if another handler was selected, do nothing
      filter((selection) => selection?.handler === 'auth0_redirect'),
      map(() => appState?.returnTo || '/'),
      switchMap((returnTo) =>
        // proactively check for a dispensary redirect to avoid a double redirect (i.e. if
        // the user will be redirected to a dispensary, take them there directly)
        dispensaryExternalEffect$.pipe(
          // exclude `notify` effect
          filter(({ _tag }) => _tag === 'redirect'),
          map(({ dispensary }) => {
            // take the user directly to their dispensary
            const dispensaryReturnTo = addDispensaryBasePathToPath(dispensary.slug, returnTo)
            return () => navigate(dispensaryReturnTo, { replace: true })
          }),
          // if there is no dispensary redirect, take the user to the original `returnTo`
          defaultIfEmpty(() =>
            // Use Gatsby's navigate method to replace the url
            navigate(returnTo, { replace: true })
          )
        )
      )
    )
    .subscribe((effect) => effect())
}
