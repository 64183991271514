import { ApolloError } from '@apollo/client'
import { useEffect } from 'react'

import { logAndCaptureException } from '../utils/errorTools'

interface ConditionCallback {
  (error: ApolloError | Error): boolean
}

const useLogAndCaptureError = (
  error?: ApolloError | Error | null,
  condition?: ConditionCallback
): void => {
  useEffect(() => {
    // execute condition callback if provided
    if (error && (!condition || condition(error))) {
      logAndCaptureException(error)
    }
  }, [condition, error])
}

export default useLogAndCaptureError
