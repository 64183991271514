import { always, append, applyTo, curry, equals, mergeWith, unfold, unless } from 'ramda'

import { EmptyObject } from './types'

/**
 * Transformation function to retain existing property if equal to new value
 */
export const alwaysUnlessEquals = <T>(newValue: T): ((value: T) => T) =>
  unless(equals(newValue), always(newValue))

export const appendToList = <T>(list: T[]) => (item: T): T[] => append(item, list)

export const rangeStepPositive = curry((start: number, end: number, step: number) =>
  unfold((n) => (n < end ? [n, n + step] : false), start)
)

/**
 * Merges two objects, such that the second object's values take precedence over the first
 * object's values. However, if the second object's value is undefined, then it is ignored.
 * This is useful for merging default options with user-provided options that have
 * optional values (which may be undefined).
 */
export const mergeUndefinedRight = <T extends EmptyObject>(leftObj: T, rightObj: T): T =>
  mergeWith<T, T>(
    (valueLeft, valueRight) => (valueRight === undefined ? valueLeft : valueRight),
    leftObj,
    rightObj
  )

export const anyTrue = <A>(...predicates: ((input: A) => boolean)[]) => (input: A) =>
  predicates.some(applyTo(input))
