import { ApolloError, useMutation } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { from } from 'rxjs'
import { CreateEmptyCartDocument } from '../../../graphql/magento'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'

export type UseGuestCartId = [
  getGuestCartId: () => void,
  state: {
    cartId?: string
    loading: boolean
    error?: ApolloError | Error
    called: boolean
    recreated: boolean
    clearCartId: () => void
    recreateCartId: () => void
  }
]

export const useGuestCartId = (): UseGuestCartId => {
  const [cartId, setCartId, clearCartIdStorage] = useLocalStorage('guest_cart_id', '')
  const [called, setCalled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [recreated, setRecreated] = useState<boolean>(false)
  const [error, setError] = useState<ApolloError | Error | undefined>(undefined)

  const [createEmptyCart] = useMutation(CreateEmptyCartDocument)

  useLogAndCaptureError(error)

  const getGuestCartId = useCallback(() => {
    setCalled(true)
  }, [])

  const clearCartId = useCallback(() => {
    clearCartIdStorage()
    setCalled(false)
  }, [clearCartIdStorage])

  const createEmptyCartAndUpdateState = useCallback(() => {
    setLoading(true)
    setError(undefined)
    return from(createEmptyCart()).subscribe({
      next(result) {
        const firstError = result.errors?.[0]
        if (firstError) {
          setError(firstError)
          setLoading(false)
          return
        }
        const newCartId = result.data?.createEmptyCart
        if (!newCartId) {
          setError(new Error('Empty response from createEmptyCart mutation'))
          setLoading(false)
          return
        }

        setCartId(newCartId)
        setLoading(false)
      },
      error(err) {
        setError(err instanceof Error ? err : new Error(`${err}`))
        setLoading(false)
      },
    })
  }, [createEmptyCart, setCartId])

  const recreateCartId = useCallback(() => {
    clearCartIdStorage()
    setCalled(true)
    setRecreated(true)
    // createEmptyCart is called by useEffect
  }, [clearCartIdStorage, setCalled])

  useEffect(() => {
    if (!called) {
      return
    }
    if (cartId) {
      setLoading(false)
      setError(undefined)
      return
    }
    const subscription = createEmptyCartAndUpdateState()
    return () => subscription.unsubscribe()
  }, [called, cartId, createEmptyCartAndUpdateState])

  return [
    getGuestCartId,
    {
      cartId: cartId || undefined,
      loading,
      error,
      called,
      recreated,
      clearCartId,
      recreateCartId,
    },
  ]
}
