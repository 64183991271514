import { useContext } from 'react'
import { DispensaryContext } from '../context'
import type { DispensaryContextValue } from '../types'

export const useDispensaryContext = (): DispensaryContextValue | null => {
  const dispensary = useContext(DispensaryContext)
  if (!dispensary) {
    return null
  }
  return dispensary
}
