import { useCallback } from 'react'
import {
  GetEncodedDispensaryPromosDocument,
  GetEncodedDispensaryPromosQuery,
} from '../../../graphql/camel'
import useCamelLazyQuery from '../../../hooks/useCamelLazyQuery'
import { usePromisifyLazy } from '../../../hooks/usePromisifyLazy'
import { useDispensaryContext } from './useDispensaryContext'

export type UseDispensaryPromosAsync = {
  getDispensaryPromos: () => Promise<GetEncodedDispensaryPromosQuery | undefined>
}

export const useDispensaryPromosAsync = (): UseDispensaryPromosAsync => {
  const dispensary = useDispensaryContext()
  const [
    getEncodedDispensaryPromos,
    { called, loading, error, data },
  ] = useCamelLazyQuery(GetEncodedDispensaryPromosDocument, { noAuth: true })

  const { load } = usePromisifyLazy({
    doRequest: getEncodedDispensaryPromos,
    called,
    loading,
    error,
    data,
  })

  const getDispensaryPromos = useCallback(async (): Promise<
    GetEncodedDispensaryPromosQuery | undefined
  > => {
    const dispensaryPromos = await load({ variables: { dispensarySlug: dispensary?.slug } })
    if (!dispensaryPromos) {
      return undefined
    }
    return dispensaryPromos
  }, [dispensary?.slug, load])

  return { getDispensaryPromos }
}
