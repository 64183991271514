import { createSignal } from '@react-rxjs/utils'
import { bind } from '@react-rxjs/core'
import { combineLatest, distinctUntilChanged, filter, map, startWith, tap } from 'rxjs'
import { logAndCaptureException } from '../../../utils/errorTools'

export interface DispensaryUserInitial {
  type: 'user_initial'
}

export interface DispensaryUserUpdateStart {
  type: 'user_update_start'
  practitionerCode: string
}

export interface DispensaryUserUpdateComplete {
  type: 'user_update_complete'
  practitionerCode: string
}

export interface DispensaryUserFinal {
  type: 'user_final'
}

export interface DispensaryUserError {
  type: 'user_error'
  error: Error
}

export type DispensaryUserState =
  | DispensaryUserInitial
  | DispensaryUserUpdateStart
  | DispensaryUserUpdateComplete
  | DispensaryUserFinal
  | DispensaryUserError

const initialState: DispensaryUserState = { type: 'user_initial' }

export const [dispensaryUserStateChange$, setDispensaryUserState] = createSignal<
  DispensaryUserState
>()

export const dispensaryUserState$ = dispensaryUserStateChange$.pipe(
  startWith<DispensaryUserState>({ ...initialState })
)

const dispensaryUserStart$ = dispensaryUserState$.pipe(
  filter((event): event is
    | DispensaryUserInitial
    | DispensaryUserUpdateStart
    | DispensaryUserFinal => {
    return (
      event.type === 'user_initial' || event.type.endsWith('_start') || event.type === 'user_final'
    )
  }),
  distinctUntilChanged()
)

const dispensaryUserComplete$ = dispensaryUserState$.pipe(
  filter((event): event is
    | DispensaryUserInitial
    | DispensaryUserUpdateComplete
    | DispensaryUserFinal => {
    return (
      event.type === 'user_initial' ||
      event.type.endsWith('_complete') ||
      event.type === 'user_final'
    )
  }),
  distinctUntilChanged()
)

const dispensaryUserLoading$ = dispensaryUserState$.pipe(
  map((event): boolean => {
    return event.type !== 'user_initial' && event.type !== 'user_final'
  }),
  distinctUntilChanged()
)

const dispensaryUserCalled$ = dispensaryUserState$.pipe(
  map((event): boolean => {
    return event.type !== 'user_initial'
  }),
  distinctUntilChanged()
)

const dispensaryUserError$ = dispensaryUserState$.pipe(
  filter((event): event is DispensaryUserError => {
    return event.type === 'user_error'
  }),
  map(({ error }): Error => error),
  tap((error) => logAndCaptureException(error))
)

const dispensaryUserStatusChange$ = combineLatest(
  dispensaryUserStart$,
  dispensaryUserComplete$,
  dispensaryUserLoading$,
  dispensaryUserCalled$,
  dispensaryUserError$.pipe(startWith(undefined))
).pipe(
  map(([startState, completeState, loading, called, error]) => ({
    startState,
    completeState,
    loading,
    called,
    error,
  }))
)

export const [useDispensaryUserStatus] = bind(dispensaryUserStatusChange$, {
  startState: { ...initialState },
  completeState: { ...initialState },
  loading: false,
  called: false,
  error: undefined,
})
