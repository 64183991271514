import React from 'react'
import { CompletedCartsContext } from '../lib/completed-carts/context'
import { useCompletedCartsGarbageCollect } from '../lib/completed-carts/hooks/useCompletedCartsGarbageCollect'
import { useCompletedCartsContextValue } from '../lib/completed-carts/hooks/useCompletedCartsContextValue'

export interface CompletedCartsContextProviderProps {
  children: React.ReactNode
}

const CompletedCartsContextProvider: React.FC<CompletedCartsContextProviderProps> = ({
  children,
}) => {
  const contextValue = useCompletedCartsContextValue()
  useCompletedCartsGarbageCollect()

  return (
    <CompletedCartsContext.Provider value={contextValue}>{children}</CompletedCartsContext.Provider>
  )
}

export default CompletedCartsContextProvider
