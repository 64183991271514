import { createContext } from 'react'
import { CompletedCart } from './types'

export interface CompletedCarts {
  find(
    props: { mode: 'cartId'; cartId: string } | { mode: 'completedAt'; completedAt: string }
  ): CompletedCart | undefined

  mostRecent(): CompletedCart | undefined

  push(completedCart: CompletedCart): void
}

export const CompletedCartsContext = createContext<CompletedCarts>({
  find() {
    console.error('CompletedCartsContext not initialized')
    return undefined
  },
  mostRecent() {
    console.error('CompletedCartsContext not initialized')
    return undefined
  },
  push() {
    console.error('CompletedCartsContext not initialized')
  },
})
