import { ApolloError, useLazyQuery } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { CartFragment, GetGuestCartDocument } from '../../../graphql/magento'
import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'
import { isCartNotFoundError } from '../utils'
import { useGuestCartId } from './useGuestCartId'

export type UseGuestCartGet = [
  getGuestCart: (shouldRefetch: boolean) => void,
  state: {
    cart?: CartFragment
    loading: boolean
    error?: ApolloError | Error
    called: boolean
    cartId: string | undefined
  },
  methods: {
    clearCartId: () => void
  }
]

export const useGuestCartGet = (): UseGuestCartGet => {
  const [called, setCalled] = useState(false)
  const [
    doGetGuestCartId,
    {
      cartId,
      loading: loadingCartId,
      error: errorCartId,
      called: calledCartId,
      recreated: recreatedCartId,
      clearCartId: clearGuestCartId,
      recreateCartId,
    },
  ] = useGuestCartId()

  const [
    doGetGuestCart,
    { data, loading: loadingCart, error: errorCart, called: calledCart, refetch: refetchGuestCart },
  ] = useLazyQuery(GetGuestCartDocument, {
    // return partial data if available
    errorPolicy: 'all',
  })

  const getGuestCart = useCallback(
    (shouldRefetch: boolean) => {
      shouldRefetch ? refetchGuestCart() : setCalled(true)
    },
    [refetchGuestCart]
  )

  const loading = loadingCartId || loadingCart

  const isCartNotFound = useMemo(() => isCartNotFoundError(errorCart), [errorCart])
  const error = errorCartId || (isCartNotFound ? undefined : errorCart)

  useLogAndCaptureError(error)

  const cart = data?.cart || undefined

  const clearCartId = useCallback(() => {
    clearGuestCartId()
    setCalled(false)
  }, [clearGuestCartId])

  useEffect(() => {
    if (!called || loadingCartId || calledCartId || errorCartId) {
      return
    }
    if (cartId) {
      return
    }
    doGetGuestCartId()
  }, [called, cartId, doGetGuestCartId, loadingCartId, calledCartId, errorCartId])

  useEffect(() => {
    if (!called || loading || recreatedCartId) {
      return
    }
    if (!isCartNotFoundError(errorCart)) {
      return
    }
    recreateCartId()
  }, [called, loading, errorCart, recreateCartId, recreatedCartId])

  useEffect(() => {
    if (!called || loadingCart || calledCart || errorCart) {
      return
    }
    if (!cartId) {
      return
    }
    doGetGuestCart({
      variables: { cartId },
    })
  }, [called, cartId, doGetGuestCart, loadingCart, calledCart, errorCart])

  useEffect(() => {
    if (!recreatedCartId || loadingCartId || !cartId || !refetchGuestCart) {
      return
    }
    refetchGuestCart({ cartId })
  }, [cartId, loadingCartId, recreatedCartId, refetchGuestCart])

  return [getGuestCart, { cart, loading, error, called, cartId }, { clearCartId }]
}
