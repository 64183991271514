import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { Dispensary, GetDispensaryDocument } from '../../../graphql/camel'

export interface UseDispensaryOptions {
  slug?: string
}

export interface UseDispensary {
  loading: boolean
  data: Dispensary | null
  error?: ApolloError | Error
  called: boolean
}

export const useDispensary = ({ slug }: UseDispensaryOptions): UseDispensary => {
  // resolve dispensary query synchronously when cached
  const { loading, data: responseData, error: queryError, called } = useQuery(
    GetDispensaryDocument,
    {
      context: {
        uri: process.env.GATSBY_CAMEL_URL,
      },
      skip: !slug,
      variables: {
        dispensarySlug: slug ?? '',
      },
    }
  )

  const error = !slug ? new Error('Invalid empty slug for dispensary.') : queryError
  const data = responseData?.getDispensary || null

  return {
    loading,
    data,
    error,
    called,
  }
}
