import { OptionsObject, SnackbarMessage, useSnackbar } from 'notistack'
import { useCallback, useContext, useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { ToastContext, ToastId } from '../../toasts/context'

export interface UseUniqueToastOptions {
  toastIdPrefix: string
}

export type UniqueToastOptions = Omit<OptionsObject, 'key' | 'preventDuplicate'>

export interface UseUniqueToast {
  showToast: (message: SnackbarMessage, options?: UniqueToastOptions) => void
  hideToast: () => void
  hideToastById: (toastId: ToastId) => void
}

export const useUniqueToast = ({ toastIdPrefix }: UseUniqueToastOptions): UseUniqueToast => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { toasts, hideToast, showToast } = useContext(ToastContext)

  const shownToastIds = useMemo(
    () => toasts.filter((toastId) => `${toastId}`.startsWith(toastIdPrefix)),
    [toastIdPrefix, toasts]
  )

  const hideToastById = useCallback(
    (toastId: ToastId) => {
      closeSnackbar(toastId)
      hideToast(toastId)
    },
    [closeSnackbar, hideToast]
  )

  const hideShownToasts = useCallback(() => {
    shownToastIds.forEach((toastId) => hideToastById(toastId))
  }, [shownToastIds, hideToastById])

  const showUniqueToast = useCallback(
    (message: SnackbarMessage, options?: UniqueToastOptions) => {
      const toastId = `${toastIdPrefix}${uuid()}`
      hideShownToasts()
      enqueueSnackbar(message, {
        ...options,
        key: toastId,
        preventDuplicate: true,
        onClose: (...args) => {
          options?.onClose?.(...args)
          hideToast(toastId)
        },
      })
      showToast(toastId)
    },
    [enqueueSnackbar, hideShownToasts, hideToast, showToast, toastIdPrefix]
  )

  return { showToast: showUniqueToast, hideToast: hideShownToasts, hideToastById }
}
