import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import fetch from 'isomorphic-fetch'
import React, { useContext, useEffect, useRef } from 'react'

import { TokenContext } from '../layouts/context'
import { latestApolloClient$, setApolloClient } from '../lib/graphql/apollo'
import { logAndCaptureException } from '../utils/errorTools'

const link = new HttpLink({
  uri: process.env.GATSBY_MAGENTO_URL,
  fetch,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const { token } = operation.getContext()

  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }
  return forward(operation)
})

const client = new ApolloClient({
  link: authMiddleware.concat(link),
  cache: new InMemoryCache({
    typePolicies: {
      Cart: {
        fields: {
          available_payment_methods: {
            merge: false,
          },
        },
      },
      // use `email` field for M2 customer type since `id` in response is null
      Customer: {
        keyFields: ['email'],
      },
      // specify key fields for types without `id` field
      CustomerAutoShip: {
        keyFields: ['autoShipId'],
      },
      CustomerOrder: {
        keyFields: ['orderId'],
      },
      CustomerInvoice: {
        keyFields: ['invoiceId'],
      },
      CustomerRebateRedemptionCashRequest: {
        keyFields: ['requestId'],
      },
      FacetedResultsAggregation: {
        keyFields: false,
      },
      SelectedCustomizableOption: {
        keyFields: false,
      },
      SimpleProduct: {
        keyFields: ['sku'],
      },
      TokenBaseCard: {
        keyFields: ['hash'],
      },
      Query: {
        fields: {
          getCustomerOrders: {
            keyArgs: ['customerId'],
            merge(existing, incoming) {
              if (!existing) {
                return incoming
              }
              const existingPageInfo = existing?.meta?.pageInfo
              const incomingPageInfo = incoming?.meta?.pageInfo
              // This logic assumes a "load more" behavior: incoming pages should always append
              // to the existing data. Return existing data if otherwise
              if (
                !existingPageInfo ||
                !incomingPageInfo ||
                incomingPageInfo.currentPage <= existingPageInfo.currentPage
              ) {
                return existing
              }
              return {
                ...incoming,
                orders: [...(existing.orders || []), ...(incoming.orders || [])],
              }
            },
          },
          getCustomerInvoices: {
            keyArgs: ['customerId'],
            merge(existing, incoming) {
              if (!existing) {
                return incoming
              }
              const existingPageInfo = existing?.meta?.pageInfo
              const incomingPageInfo = incoming?.meta?.pageInfo
              // This logic assumes a "load more" behavior: incoming pages should always append
              // to the existing data. Return existing data if otherwise
              if (
                !existingPageInfo ||
                !incomingPageInfo ||
                incomingPageInfo.currentPage <= existingPageInfo.currentPage
              ) {
                return existing
              }
              return {
                ...incoming,
                invoices: [...(existing.invoices || []), ...(incoming.invoices || [])],
              }
            },
          },
          getCustomerRewardsOrders: {
            keyArgs: ['customerId', 'rewardsSource'],
            merge(existing, incoming) {
              if (!existing) {
                return incoming
              }
              const existingPageInfo = existing?.meta?.pageInfo
              const incomingPageInfo = incoming?.meta?.pageInfo
              // This logic assumes a "load more" behavior: incoming pages should always append
              // to the existing data. Return existing data if otherwise
              if (
                !existingPageInfo ||
                !incomingPageInfo ||
                incomingPageInfo.currentPage <= existingPageInfo.currentPage
              ) {
                return existing
              }
              return {
                ...incoming,
                orders: [...(existing.orders || []), ...(incoming.orders || [])],
              }
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  connectToDevTools: true,
})

const AuthorizedApolloProvider: React.FC = ({ children }) => {
  const token = useContext(TokenContext)
  // initial value should be undefined
  const latestTokenRef = useRef<string | undefined>(token)
  useEffect(() => {
    // avoid resetting store when token initially received
    if (latestTokenRef.current && latestTokenRef.current !== token) {
      // TODO: does this have the desired effect since token is set in context during hook execution?
      // user signed out or token updated - clear cache and refetch
      client.resetStore().catch((err) => {
        logAndCaptureException(err)
      })
    }
    latestTokenRef.current = token
  }, [token])

  // hold subscription for child components
  useEffect(() => {
    const subscription = latestApolloClient$.subscribe()
    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    setApolloClient(client)
  }, [])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
