import { useLocation } from '@gatsbyjs/reach-router'
import queryString, { ParsedQuery } from 'query-string'
import { useCallback } from 'react'

import { usePageUrl } from '../lib/pages'

export const useRemoveParamsFromUrl = () => {
  const { search, pathname, hash } = useLocation()

  const { navigate } = usePageUrl()

  return useCallback(
    (paramsToRemove: ReadonlyArray<string>) => {
      if (typeof window === 'undefined') {
        return
      }
      const queryParams = queryString.parse(search || '', { arrayFormat: 'comma' })

      const newQueryParams = Object.entries(queryParams).reduce<ParsedQuery>(
        (acc, [key, value]) =>
          // if a param that shouldn't be removed where to appear multiple times in the query string,
          // this would keep only the last one. E.g. `?foo=bar&foo=baz` would become `?foo=baz`
          paramsToRemove.includes(key) ? acc : { ...acc, [key]: value },
        {}
      )

      const newQueryString = queryString.stringify(newQueryParams, { arrayFormat: 'comma' })
      const newSearch = newQueryString ? `?${newQueryString}` : ''
      const newUrl = `${pathname}${newSearch}${hash}`
      if (search === newSearch) {
        return
      }
      navigate(newUrl, { replace: true })
    },
    [search, pathname, hash, navigate]
  )
}
