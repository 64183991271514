import { bind, state } from '@react-rxjs/core'
import { createSignal } from '@react-rxjs/utils'
import { timer } from 'rxjs'
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators'

import { latestAccessToken$, latestAccessTokenFactory$, setAccessToken } from './state'

export type TokenExpirationState =
  | { status: 'active'; expiresAt: number; count: number }
  | { status: 'expiringSoon'; expiresAt: number; count: number }
  | { status: 'expired'; expiresAt: number; count: number }

const statusCheckInterval: Record<TokenExpirationState['status'], number> = {
  active: 60 * 1000, // check expiration every 1 minute
  expiringSoon: 1000, // check every 1 second to show timer
  expired: 60 * 1000, // check every 1 minute after expiration just as fallback
}

const expiringSoonThreshold = 180 * 1000 // 3 minutes before expiration

const [statusCheckInterval$, setStatusCheckInterval] = createSignal<number>()
const latestStatusCheckInterval$ = state(
  statusCheckInterval$.pipe(distinctUntilChanged()),
  statusCheckInterval.active
)

const tokenExpiresAt = (token: string) => {
  const { exp } = JSON.parse(atob(token.split('.')[1]))
  return exp * 1000
}

const accessTokenExpirationState = ({
  expiresAt,
  expiringSoonAt,
}: {
  expiresAt: number
  expiringSoonAt: number
}) => (intervalValue: number): TokenExpirationState =>
  Date.now() >= expiresAt
    ? { status: 'expired' as const, expiresAt, count: intervalValue + 1 }
    : Date.now() >= expiringSoonAt
    ? { status: 'expiringSoon' as const, expiresAt, count: intervalValue + 1 }
    : { status: 'active' as const, expiresAt, count: intervalValue + 1 }

export const accessTokenExpiration$ = latestAccessToken$.pipe(
  filter((token): token is string => !!token),
  distinctUntilChanged(),
  map(tokenExpiresAt),
  map((expiresAt) => ({ expiresAt, expiringSoonAt: expiresAt - expiringSoonThreshold })),
  switchMap(({ expiresAt, expiringSoonAt }) =>
    latestStatusCheckInterval$.pipe(
      switchMap((intervalTimeout) =>
        timer(0, intervalTimeout).pipe(
          map(accessTokenExpirationState({ expiresAt, expiringSoonAt })),
          distinctUntilChanged(
            (previous, current) => JSON.stringify(previous) === JSON.stringify(current)
          ),
          tap(({ status }) => setStatusCheckInterval(statusCheckInterval[status]))
        )
      )
    )
  )
)

export const refreshAccessToken = () =>
  latestAccessTokenFactory$.pipe(
    switchMap((getAccessToken) => getAccessToken({ ignoreCache: true, updateTokenContext: true })),
    tap((token) => setAccessToken(token))
  )

export const [useAccessTokenExpiration, latestAccessTokenExpiration$] = bind(accessTokenExpiration$)
