import { useEffect, useMemo } from 'react'
import {
  GetEncodedDispensaryPromosDocument,
  GetEncodedDispensaryPromosQuery,
  GetEncodedDispensaryPromosQueryVariables,
} from '../../../graphql/camel'
import useCamelLazyQuery, { UseCamelLazyQuery } from '../../../hooks/useCamelLazyQuery'
import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'
import { useDispensaryContext } from './useDispensaryContext'

export type UseDispensaryPromos = UseCamelLazyQuery<
  GetEncodedDispensaryPromosQuery,
  GetEncodedDispensaryPromosQueryVariables
>[1]

export const useDispensaryPromos = (): UseDispensaryPromos => {
  const dispensary = useDispensaryContext()
  const [
    getEncodedDispensaryPromos,
    lazyQueryResult,
  ] = useCamelLazyQuery(GetEncodedDispensaryPromosDocument, { noAuth: true })

  useLogAndCaptureError(lazyQueryResult.error)

  useEffect(() => {
    // fetch encoded dispensary promos in global and dispensary contexts (promos will vary)
    getEncodedDispensaryPromos({ variables: { dispensarySlug: dispensary?.slug } })
  }, [getEncodedDispensaryPromos, dispensary?.slug])

  return useMemo(() => {
    const { called, loading, ...restResult } = lazyQueryResult
    return {
      ...restResult,
      called,
      loading: !called && dispensary?.slug ? true : loading,
    }
  }, [dispensary?.slug, lazyQueryResult])
}
