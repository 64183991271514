import { useCallback, useEffect, useState } from 'react'

import useIsMounted from '../../../hooks/useIsMounted'
import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'
import { useCartAllowGuest } from '../../cart'
import { useDispensaryUpdateCart } from './useDispensaryUpdateCart'
import { useDispensaryUpdateUser } from './useDispensaryUpdateUser'
import { useShouldUpdateDispensary } from './useShouldUpdateDispensary'
import { useUserDispensary } from './useUserDispensary'

export interface UseDispensaryUpdateSession {
  loading: boolean
  called: boolean
  error: Error | undefined
}

export const useDispensaryUpdateSession = (): UseDispensaryUpdateSession => {
  const [loading, setLoading] = useState(false)
  const [called, setCalled] = useState(false)
  const [error, setError] = useState<Error | undefined>(undefined)
  const isMounted = useIsMounted()

  useCartAllowGuest({ allowGuestCart: true })

  const { shouldUpdateDispensary, refreshClaims } = useShouldUpdateDispensary()
  const { attemptRedirect } = useUserDispensary()
  const [updateUserDispensary] = useDispensaryUpdateUser({ refreshClaims })
  const [updateCartDispensary] = useDispensaryUpdateCart()

  useLogAndCaptureError(error)

  const setErrorIfMounted = useCallback(
    (errorThrown: unknown) => {
      if (isMounted.current) {
        setError(errorThrown instanceof Error ? errorThrown : new Error(`${errorThrown}`))
      }
    },
    [isMounted]
  )

  useEffect(() => {
    if (loading || called || error) {
      return
    }
    ;(async () => {
      try {
        setLoading(true)
        setCalled(true)
        const didRedirect = await attemptRedirect()
        if (didRedirect) {
          setLoading(false)
          return
        }
        const { shouldUpdateUser, shouldUpdateCart } = await shouldUpdateDispensary()
        /*
         * Update cart and user in parallel:
         * - parallel execution reduces overall processing time
         * - dispensary cart status does not change to `loading` until `updateCartDispensary` is called
         */
        await Promise.all([
          updateUserDispensary({
            shouldUpdateDispensary: shouldUpdateUser,
          }).catch(setErrorIfMounted),
          updateCartDispensary({
            forceClearEScriptParams: shouldUpdateCart,
            shouldClearDispensaryPromoCode: shouldUpdateCart,
          }).catch(setErrorIfMounted),
        ])
      } catch (updateSessionError) {
        setErrorIfMounted(updateSessionError)
      } finally {
        if (isMounted.current) {
          setLoading(false)
        }
      }
    })()
  }, [
    loading,
    called,
    error,
    updateCartDispensary,
    updateUserDispensary,
    shouldUpdateDispensary,
    attemptRedirect,
    isMounted,
    setErrorIfMounted,
  ])

  return { loading, called, error }
}
